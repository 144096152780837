<template>
  <div class="contact-us-wrap">
    <div class="image-top">
      <img src="@/assets/image/ContactUs/banner_contact_us.png" />
    </div>
    <div class="contact-us-wrap-content">
      <div class="title">
        <div class="title-text">{{ $t("ContactUs.title") }}</div>
        <div class="title-p">{{ $t("ContactUs.text") }}</div>
      </div>
      <div class="contact d-flex flex-y-center flex-x-between">
        <div v-for="(item, index) in contactList" :key="index">
          <div class="contact-logo"><img :src="item.logo" /></div>
          <div class="contact-QRcord"><img :src="item.QRcord" /></div>
          <div class="contact-icon"><img :src="item.icon" /></div>
          <div class="contact-name">{{ item.name }}</div>
        </div>
      </div>
      <!-- <div class="phone d-flex flex-y-center flex-x-between flex-x-wrap">
        <div v-for="(item,index) in contactPhone" :key="index" class="phone-box">
          <div class="phone-title">{{item.name}}</div>
          <div class="phoeNum d-flex flex-y-center">
            <div class="phone-icon"><img src="../../assets/image/icon_contact_phone.png"></div><span>{{item.phoneNum}}</span>
            <div class="phone-icon right"><img :src="item.icon"></div><span>{{item.faxOrEmail}}</span>
          </div>
        </div>
      </div> -->
      <div class="phone d-flex flex-y-center flex-x-between">
        <div v-for="(item, index) in contactPhone" :key="index">
          <div class="phone-title">{{ item.name }}</div>
          <div class="phoeNum d-flex flex-y-center">
            <div class="phone-icon">
              <img src="../../assets/image/ContactUs/icon_contact_phone.png" />
            </div>
            {{ item.phoneNum }}
          </div>
          <div class="phoeNum d-flex flex-y-center">
            <div class="phone-icon"><img :src="item.icon" /></div>
            {{ item.faxOrEmail }}
          </div>
        </div>
      </div>
      <div class="address d-flex flex-y-center flex-x-between">
        <div class="address-item" v-for="(item, index) in address" :key="index">
          <div class="address-title">{{ item.cityName }}</div>
          <div class="address-text">{{ item.address }}</div>
        </div>
      </div>
    </div>
    <bottom></bottom>
  </div>
</template>

<script>
import bottom from "@/components/bottom.vue";
export default {
  name: "ContactUs",
  components: {
    bottom
  },
  data() {
    return {
      contactList: [
        {
          logo: require("@/assets/image/ContactUs/icon_contact_logo_1690.png"),
          QRcord: require("@/assets/image/ContactUs/QRcord_1690.png"),
          icon: require("@/assets/image/ContactUs/icon_contact_logo_1690_s.png"),
          name: "AI投資社交交易平台"
        },
        {
          logo: require("@/assets/image/ContactUs/icon_contact_logo_in.png"),
          QRcord: require("@/assets/image/ContactUs/QRcord_in.png"),
          icon: require("@/assets/image/ContactUs/icon_contact_logo_in_s.png"),
          name: "FUTECfinancial"
        },
        {
          logo: require("@/assets/image/ContactUs/icon_contact_logo_fb.png"),
          QRcord: require("@/assets/image/ContactUs/QRcord_fb.png"),
          icon: require("@/assets/image/ContactUs/icon_contact_logo_fb_s.png"),
          name: "FUTECfinancial"
        },
        {
          logo: require("@/assets/image/ContactUs/icon_contact_logo_ins.png"),
          QRcord: require("@/assets/image/ContactUs/QRcord_ins.png"),
          icon: require("@/assets/image/ContactUs/icon_contact_logo_ins_s.png"),
          name: "FUTECfinancial"
        },
        {
          logo: require("@/assets/image/ContactUs/icon_contact_logo_wc.png"),
          QRcord: require("@/assets/image/ContactUs/QRcord_wc.png"),
          icon: require("@/assets/image/ContactUs/icon_contact_logo_wc_s.png"),
          name: "服務號：FUTEC-cs"
        }
      ],
      contactPhone: [
        {
          name: "General Line",
          phoneNum: "(852) 3972 0700",
          icon: require("@/assets/image/ContactUs/icon_contact_fax.png"),
          faxOrEmail: "(852) 3972 0730"
        },
        {
          name: "Customer Service",
          phoneNum: "(852) 3972 0708",
          icon: require("@/assets/image/ContactUs/icon_contact_email.png"),
          faxOrEmail: "cs@futec.com"
        },
        // {
        //   name: 'Retail Investors',
        //   phoneNum: '(852) 3972 0733',
        //   icon: require('@/assets/image/icon_contact_email.png'),
        //   faxOrEmail: 'cs@heungkongfinancial.com'
        // },
        {
          name: "Career Development",
          phoneNum: "(852) 3972 0777",
          icon: require("@/assets/image/ContactUs/icon_contact_email.png"),
          faxOrEmail: "hr@futec.com"
        }
      ],
      address: [
        {
          cityName: "Hong Kong ",
          address:
            "Suite 622, Ocean Centre, Harbour City, Tsim Sha Tsui, Hong Kong"
        },
        {
          cityName: "Shenzhen",
          address: "Heungkong Financial Center, Qianhai, Shenzhen"
        }
      ]
    };
  }
};
</script>

<style lang="scss" scoped>
.contact-us-wrap {
  .image-top {
    width: 100%;
    height: 638px;
    img {
      width: 100%;
      height: 100%;
      object-fit: cover;
    }
  }
  .contact-us-wrap-content {
    width: 62.5vw;
    margin: 0 auto 100px;
    .title {
      .title-text {
        color: #00b2e3;
        font-size: 32px;
        font-weight: 500;
        // margin-top: 109px;
      }
      .title-p {
        font-size: 16px;
        color: #004877;
      }
    }
    .contact {
      margin-top: 60px;
      .contact-logo {
        width: 90px;
        height: 90px;
        margin: auto;
        display: flex;
        img {
          width: 100%;
          height: 100%;
          object-fit: contain;
        }
      }
      .contact-QRcord {
        width: 130px;
        height: 130px;
        margin: auto;
        display: flex;
        img {
          width: 100%;
          height: 100%;
          object-fit: contain;
        }
      }
      .contact-icon {
        width: 88px;
        height: 30px;
        margin: auto;
        display: flex;
        img {
          width: 100%;
          height: 100%;
          object-fit: contain;
        }
      }
      .contact-name {
        font-size: 16px;
        color: #000;
        margin: auto;
      }
    }
    .contact :first-child {
      .contact-logo {
        width: 120px;
        height: 90px;
        margin: auto;
        display: flex;
        img {
          width: 100%;
          height: 100%;
          object-fit: contain;
        }
      }
    }
    .phone {
      margin-top: 50px;
      text-align: left;
      color: #666666;
      font-size: 18px;
      // .phone-box {
      //   // width: 50%;
      //   margin-bottom: 20px;
      .phone-title {
        color: #004877;
        font-size: 21px;
        font-weight: 500;
        margin: 10px 0;
      }
      .phoeNum {
        margin-top: 5px;
        .phone-icon {
          width: 30px;
          height: 30px;
          margin: auto 5px auto 0;
          display: flex;
          img {
            width: 100%;
            height: 100%;
            object-fit: contain;
          }
        }
        .right {
          margin-left: 20px;
        }
      }
      // }
    }
    .address {
      margin-top: 107px;
      text-align: left;
      color: #666666;
      font-size: 18px;
      .address-item {
        width: 50%;
        .address-title {
          color: #004877;
          font-size: 21px;
        }
        .address-text {
          width: 60%;
        }
      }
    }
  }
}
</style>

<template>
  <div>
    <div class="box" v-if="id === 1">
      <div class="image">
        <img src="@/assets/image/Career/img_career_chloe_wong_L@2x.png" />
      </div>
      <div class="name">{{ $t("Career.ChloeWongName") }}</div>
      <div class="text">{{ $t("Career.ChloeWongIntroduceA") }}</div>
      <div class="text">{{ $t("Career.ChloeWongIntroduceB") }}</div>
      <div class="text">{{ $t("Career.ChloeWongIntroduceC") }}</div>
      <div class="text">{{ $t("Career.ChloeWongIntroduceD") }}</div>
    </div>
    <div class="box" v-if="id === 2">
      <div class="image">
        <img src="@/assets/image/Career/img_career_sean_kan_L@2x.png" />
      </div>
      <div class="name">{{ $t("Career.SeanKanName") }}</div>
      <div class="text">{{ $t("Career.SeanKanIntroduceA") }}</div>
      <div class="text">{{ $t("Career.SeanKanIntroduceB") }}</div>
      <div class="text">{{ $t("Career.SeanKanIntroduceC") }}</div>
    </div>
    <div class="box" v-if="id === 3">
      <div class="image"><img src="@/assets/image/Career/carman.png" /></div>
      <div class="name">{{ $t("Career.CarmanName") }}</div>
      <div class="text">{{ $t("Career.CarmanIntroduceA") }}</div>
      <div class="text">{{ $t("Career.CarmanIntroduceB") }}</div>
      <div class="text">{{ $t("Career.CarmanIntroduceC") }}</div>
      <div class="text">{{ $t("Career.CarmanIntroduceD") }}</div>
      <div class="text">{{ $t("Career.CarmanIntroduceE") }}</div>
      <div class="text">{{ $t("Career.CarmanIntroduceF") }}</div>
    </div>
    <bottom></bottom>
  </div>
</template>
<script>
import bottom from "@/components/bottom.vue";
export default {
  name: "personalIntroduction",
  components: {
    bottom
  },
  data() {
    return {
      id: 0
    };
  },
  mounted() {
    document.body.scrollTop = 0;
    document.documentElement.scrollTop = 0;
    if (this.$route.params.id) {
      this.id = this.$route.params.id;
      localStorage.setItem("historyId", JSON.stringify(this.id));
    } else {
      this.id = localStorage.getItem("historyId")
        ? Number(localStorage.getItem("historyId"))
        : 0;
    }
  }
};
</script>
<style lang="scss" scoped>
.box {
  width: 100%;
  // height: 100%;
  .image {
    width: 1000px;
    height: 480px;
    margin: 50px auto 0;
    img {
      width: 100%;
      height: 100%;
      object-fit: contain;
    }
  }
  .name {
    font-size: 40px;
    color: #004877;
    text-align: center;
    margin: 58px auto 39px;
  }
  .text {
    width: 998px;
    margin: auto;
    text-align: left;
    font-size: 24px;
    color: #333333;
    margin-bottom: 50px;
  }
}
</style>

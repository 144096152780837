<template>
  <div class="statement-or-policy">
    <div class="statement-or-policy-wrap" id="statement">
      <div class="image"><img src="@/assets/image/banner_bottom@2x.png" /></div>
      <div class="list">
        <div
          class="list-item"
          :class="{ 'list-item-bg': active === index }"
          v-for="(item, index) in $t('statementOrPolicy.statementOrPolicyData')"
          :key="index"
          @click="changeList(index)"
        >
          {{ item }}
        </div>
      </div>
      <div class="Disclaimer" v-if="active === 0">
        <div
          v-for="(item, index) in $t('statementOrPolicy.DisclaimerData')"
          :key="index"
        >
          <div class="title" v-if="item.title">{{ item.title }}</div>
          <div class="text" v-if="item.text">{{ item.text }}</div>
        </div>
      </div>
      <div class="policy" v-if="active === 1">
        <div
          v-for="(item, index) in $t('statementOrPolicy.PrivacyPolicyData')"
          :key="index"
        >
          <div class="text" v-if="item.text">{{ item.text }}</div>
          <div class="text" v-if="item.list">
            <ul v-for="(item1, index1) in item.list" :key="index1">
              <li>{{ item1 }}</li>
            </ul>
          </div>
          <div class="text" v-if="item.data">
            <div v-for="(item2, index2) in item.data" :key="index2">
              {{ item2 }}
            </div>
            <div>
              Email:
              <a class="aColor" href="mailto:compliance@heungkongfinancial.com"
                >compliance@heungkongfinancial.com</a
              >
            </div>
          </div>
        </div>
      </div>
      <div class="Disclaimer" v-if="active === 2">
        <div
          v-for="(item, index) in $t('statementOrPolicy.RiskDisclosureData')"
          :key="index"
        >
          <div class="title" v-if="item.title">{{ item.title }}</div>
          <div class="text" v-if="item.text">{{ item.text }}</div>
        </div>
      </div>
    </div>
    <bottom @change="updataChange"></bottom>
  </div>
</template>
<script>
import bottom from "@/components/bottom.vue";
export default {
  name: "statementOrPolicy",
  components: {
    bottom
  },
  data() {
    return {
      active: 0
    };
  },
  mounted() {
    document.getElementById("statement").scrollIntoView();
    if (this.$route.params.id) {
      document.getElementById("statement").scrollIntoView();
      this.active = this.$route.params.id;
    }
  },
  methods: {
    changeList(index) {
      this.active = index;
    },
    updataChange(id) {
      document.getElementById("statement").scrollIntoView();
      this.active = id;
    }
  }
};
</script>
<style lang="scss" scoped>
.statement-or-policy {
  .statement-or-policy-wrap {
    margin: 0 auto 100px;
    .image {
      width: 1200px;
      height: 480px;
      margin: 50px auto 0;
      img {
        width: 100%;
        height: 100%;
        object-fit: contain;
      }
    }
    .list {
      width: 1200px;
      display: flex;
      margin: auto;
      font-size: 24px;
      position: relative;
      .list-item {
        width: 400px;
        height: 80px;
        line-height: 80px;
        background: #f0f0f0;
        border-right: 1px solid #fff;
        position: relative;
      }
      .list-item-bg {
        background: #00b2e3;
        color: #fff;
      }
      .list-item-bg::after {
        content: "";
        border: 15px solid rgba($color: #00b2e3, $alpha: 0);
        border-top-color: #00b2e3;
        position: absolute;
        top: 100%;
        left: 46.5%;
        // margin-left: -70px;
      }
    }
    .Disclaimer {
      width: 1146px;
      margin: auto;
      margin-top: 70px;
      font-size: 18px;
      color: #333333;
      text-align: left;
      .title {
        font-weight: bold;
      }
      div {
        margin-bottom: 20px;
      }
    }
    .policy {
      width: 1146px;
      margin: auto;
      font-size: 18px;
      color: #333333;
      text-align: left;
      margin-top: 70px;
      .text {
        margin-bottom: 30px;
        > ul {
          margin-left: 20px;
        }
        .aColor {
          color: #194873;
          text-decoration: none;
        }
        .aColor:hover {
          text-decoration: underline;
        }
      }
    }
  }
}
</style>

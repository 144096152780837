<template>
  <div class="investment-banking-wrap">
    <div class="investment-banking-wrap-content">
      <div class="image">
        <img src="@/assets/image/OurOffering/IBS_banner.png" />
      </div>
      <div class="d-flex flex-x-around">
        <div
          class="data-list"
          v-for="(item, index) in $t('OurOffering.investmentBankingData')"
          :key="index"
        >
          <div class="data-list-img"><img :src="item.icon" /></div>
          <div class="data-list-text">{{ item.text }}</div>
        </div>
      </div>
      <div class="swiper-container">
        <div class="swiper-wrapper">
          <div class="swiper-slide">
            <img src="@/assets/image/OurOffering/img_123_1.png" />
          </div>
          <div class="swiper-slide">
            <img src="@/assets/image/OurOffering/img_123_2.png" />
          </div>
          <div class="swiper-slide">
            <img src="@/assets/image/OurOffering/img_123_3.png" />
          </div>
        </div>

        <!-- 如果需要导航按钮 -->
        <div class="swiper-button-prev"></div>
        <div class="swiper-button-next"></div>
      </div>
      <!-- <div class="setp">
        <div class="d-flex">
          <div
            class="setp-list"
            v-for="(item, index) in $t('OurOffering.stepList')"
            :key="index"
          >
            <div class="setp-list-icon d-flex flex-x-between">
              <div>{{ item.indexs }}.</div>
              <div class="setp-icon" v-if="item.icon">
                <img :src="item.icon" />
              </div>
            </div>
            <div class="setp-list-title">{{ item.name }}</div>
          </div>
        </div>
        <div class="setp-image">
          <img src="../../../assets/image/img_our_offering_03.png" />
        </div>
      </div> -->
      <div class="setp-details">
        <div
          v-for="(item, index) in $t('OurOffering.setpDetails')"
          :key="index"
        >
          <div v-if="currentIndex === index">
            <div class="sept-details-img"><img :src="item.icon" /></div>
            <div class="setp-details-title">{{ item.title }}</div>
            <div
              class="setp-details-text"
              v-for="(item1, index1) in item.text"
              :key="index1"
            >
              {{ item1 }}
            </div>
            <ul v-for="(item2, index2) in item.text1" :key="'ul' + index2">
              <li>{{ item2 }}</li>
            </ul>
          </div>
        </div>
      </div>
      <div class="investment-banking-bottom">
        <div class="bottom-img">
          <img src="@/assets/image/OurOffering/resources.png" />
        </div>
        <div class="bottom-title">SELECTED EXPERIENCE</div>
        <div class="bottom-item">
          <span :class="{ spanBg: activeIndex == 0 }" @click="onChangeType(0)"
            >IPOs</span
          >
          <span :class="{ spanBg: activeIndex == 1 }" @click="onChangeType(1)"
            >M&A</span
          >
          <span :class="{ spanBg: activeIndex == 2 }" @click="onChangeType(2)"
            >IFA</span
          >
        </div>
        <div v-if="activeIndex === 0" class="nvestment-banking-bottom-img">
          <div><img src="@/assets/image/OurOffering/IPO_EN.png" /></div>
        </div>
        <div v-if="activeIndex === 1" class="nvestment-banking-bottom-img">
          <div><img src="@/assets/image/OurOffering/img_m&a_01.png" /></div>
        </div>
        <div v-if="activeIndex === 2" class="nvestment-banking-bottom-img">
          <div>
            <img src="@/assets/image/OurOffering/img_ifa.png" />
          </div>
        </div>
        <!-- <div>
          <div class="swiper-wrapper-bottom" v-show="activeIndex === 0">
            <div class="swiper-wrapper">
              <div class="swiper-slide">
                <img src="@/assets/image/OurOffering/img_ipo_01.png" />
              </div>
              <div class="swiper-slide">
                <img src="@/assets/image/OurOffering/img_ipo_02.png" />
              </div>
              <div class="swiper-slide">
                <img src="@/assets/image/OurOffering/img_ipo_03.png" />
              </div>
            </div>
            如果需要导航按钮
            <div class="swiper-button-prev"></div>
            <div class="swiper-button-next"></div>
          </div>
        </div>
        <div v-show="activeIndex === 1">
          <div class="swiper-wrapper-bottom-two">
            <div class="swiper-wrapper">
              <div class="swiper-slide">
                <img src="@/assets/image/OurOffering/img_m&a_01.png" />
              </div>
              <div class="swiper-slide">
                <img src="@/assets/image/OurOffering/img_m&a_02.png" />
              </div>
            </div>
            如果需要导航按钮
            <div class="swiper-button-prev"></div>
            <div class="swiper-button-next"></div>
          </div>
        </div>
        <div class="swiper-wrapper-bottom-three" v-show="activeIndex === 2">
          <div class="swiper-wrapper">
            <div class="swiper-slide">
              <img src="@/assets/image/OurOffering/img_ifa.png" />
            </div>
          </div>
        </div> -->
      </div>
    </div>
    <bottom></bottom>
  </div>
</template>

<script>
import bottom from "@/components/bottom.vue";
import Swiper from "swiper";
export default {
  name: "investmentBanking",
  components: {
    bottom
  },
  data() {
    return {
      currentIndex: 0,
      activeIndex: 0
    };
  },
  mounted() {
    document.body.scrollTop = 0;
    document.documentElement.scrollTop = 0;
    // window.scrollto(0, -10);
    const vm = this;
    new Swiper(".swiper-container", {
      loop: true,
      pagination: ".swiper-pagination",
      observer: true,
      observeParents: true,
      // 如果需要前进后退按钮
      nextButton: ".swiper-button-next",
      prevButton: ".swiper-button-prev",
      onClick: function(swiper) {
        const realIndex = swiper.realIndex;
        vm.onChangeIndex(realIndex);
      },
      onTransitionEnd: function(swiper) {
        const realIndex = swiper.realIndex;
        vm.onChangeIndex(realIndex);
      }
    });
    new Swiper(".swiper-wrapper-bottom", {
      loop: true,
      pagination: ".swiper-pagination",
      observer: true,
      observeParents: true,
      // 如果需要前进后退按钮
      nextButton: ".swiper-button-next",
      prevButton: ".swiper-button-prev"
    });
    new Swiper(".swiper-wrapper-bottom-two", {
      loop: true,
      pagination: ".swiper-pagination",
      observer: true,
      observeParents: true,
      // 如果需要前进后退按钮
      nextButton: ".swiper-button-next",
      prevButton: ".swiper-button-prev"
    });
    new Swiper(".swiper-wrapper-bottom-three", {
      // loop: true,
      pagination: ".swiper-pagination",
      observer: true,
      observeParents: true
    });
  },
  methods: {
    onChangeIndex(index) {
      this.currentIndex = index;
    },
    onChangeType(index) {
      this.activeIndex = index;
    }
  }
};
</script>

<style lang="scss" scoped>
.investment-banking-wrap {
  .investment-banking-wrap-content {
    width: 1200px;
    margin: auto;
    .image {
      width: 1200px;
      height: 480px;
      margin: 60px auto 74px;
      img {
        width: 100%;
        height: 100%;
        object-fit: contain;
      }
    }
    .data-list {
      // width: 25%;
      .data-list-img {
        width: 90px;
        height: 90px;
        margin: auto;
        display: flex;
        img {
          width: 100%;
          height: 100%;
          object-fit: contain;
        }
      }
      .data-list-text {
        margin-top: 22px;
        font-size: 16px;
        color: #333;
        text-align: center;
      }
    }
    .swiper-container {
      height: 253px;
      width: 100%;
      margin: 120px auto 0;
      .swiper-wrapper {
        .swiper-slide {
          width: 100%;
          height: 100%;
          img {
            // width: 1190px;
            width: 1100px;
            height: 253px;
            object-fit: contain;
          }
        }
      }
      .swiper-button-prev {
        background: url("../../../assets/image/OurOffering/icon_left.png")
          no-repeat no-repeat;
        background-size: 100% 100%;
      }
      .swiper-button-next {
        background: url("../../../assets/image/OurOffering/icon_right.png")
          no-repeat no-repeat;
        background-size: 100% 100%;
      }
    }
    .setp {
      margin-top: 200px;
      margin-left: 50px;
      position: relative;
      .setp-list {
        height: 375px;
        width: 238px;
        border-radius: 10px;
        background-color: #ccc;
        margin-right: 27px;
        // margin: 2% 1%;
        // margin-right: 50px;
        .setp-list-icon {
          color: #00b2e3;
          font-size: 36px;
          margin: 20px;
          .setp-icon {
            width: 52px;
            height: 35px;
            img {
              width: 100%;
              height: 100%;
              object-fit: contain;
            }
          }
        }
        .setp-list-title {
          font-size: 32px;
          font-weight: 500;
          color: #004877;
        }
      }
      .setp-image {
        position: absolute;
        left: 52%;
        top: 13%;
        width: 548px;
        height: 390px;
        img {
          width: 100%;
          height: 100%;
          object-fit: contain;
        }
      }
    }
    .setp-details {
      // width: 50vw;
      width: 966px;
      margin: auto;
      margin-bottom: 100px;
      .sept-details-img {
        width: 175px;
        height: 175px;
        margin: 8vh auto 0;
        img {
          width: 100%;
          height: 100%;
          object-fit: contain;
        }
      }
      .setp-details-title {
        font-size: 32px;
        font-weight: 500;
        color: #00b2e3;
        margin: 30px auto 33px;
      }
      .setp-details-text {
        text-align: left;
        color: #333333;
        font-size: 16px;
        font-weight: 300;
      }
      ul {
        text-align: left;
        color: #333333;
        font-size: 16px;
        font-weight: 300;
        margin-left: 30px;
      }
    }
    .investment-banking-bottom {
      .bottom-img {
        width: 172px;
        height: 171px;
        margin: 120px auto 24px;
        img {
          width: 100%;
          height: 100%;
          object-fit: cover;
        }
      }
      .bottom-title {
        font-size: 32px;
        // font-weight: bold;
        color: #00b2e3;
      }
      .bottom-item {
        display: flex;
        justify-content: space-evenly;
        margin: 60px auto;
        span {
          width: 151px;
          height: 48px;
          font-size: 32px;
          // font-weight: bold;
          color: #ffffff;
          background: url("../../../assets/image/OurOffering/butten01.png")
            no-repeat no-repeat;
          background-size: 100% 100%;
        }
        .spanBg {
          background: url("../../../assets/image/OurOffering/butten05.png")
            no-repeat no-repeat;
          background-size: 100% 100%;
        }
      }
      .nvestment-banking-bottom-img {
        margin: auto;
        .last-img {
          // width: 1051px;
          // text-align: left !important;
          margin-right: 210px;
        }
      }
      .swiper-wrapper-bottom {
        height: 218px;
        width: 100%;
        overflow: hidden;
        position: relative;
        margin-bottom: 70px;
        .swiper-wrapper {
          .swiper-slide {
            width: 1051px;
            height: 218px;
            img {
              width: 100%;
              height: 100%;
              object-fit: contain;
            }
          }
        }
        .swiper-button-prev {
          background: url("../../../assets/image/OurOffering/icon_left_1.png")
            no-repeat no-repeat;
          background-size: 100% 100%;
        }
        .swiper-button-next {
          background: url("../../../assets/image/OurOffering/icon_right_1.png")
            no-repeat no-repeat;
          background-size: 100% 100%;
        }
      }
      .swiper-wrapper-bottom-two {
        height: 218px;
        width: 100%;
        overflow: hidden;
        position: relative;
        margin-bottom: 70px;
        .swiper-wrapper {
          .swiper-slide {
            width: 1051px;
            height: 218px;
            img {
              width: 100%;
              height: 100%;
              object-fit: contain;
            }
          }
        }
        .swiper-button-prev {
          background: url("../../../assets/image/OurOffering/icon_left_1.png")
            no-repeat no-repeat;
          background-size: 100% 100%;
        }
        .swiper-button-next {
          background: url("../../../assets/image/OurOffering/icon_right_1.png")
            no-repeat no-repeat;
          background-size: 100% 100%;
        }
      }
      .swiper-wrapper-bottom-three {
        height: 218px;
        width: 100%;
        overflow: hidden;
        position: relative;
        margin-bottom: 70px;
        .swiper-wrapper {
          .swiper-slide {
            width: 1051px;
            height: 218px;
            img {
              width: 100%;
              height: 100%;
              object-fit: contain;
            }
          }
        }
      }
    }
  }
}
</style>

<template>
  <div class="asset-wrap">
    <div class="asset-wrap-content">
      <div class="image">
        <img src="@/assets/image/OurOffering/banner_our_offering_01.png" />
      </div>
      <div class="d-flex flex-x-around">
        <div
          class="list"
          v-for="(item, index) in $t('OurOffering.assetManagementData')"
          :key="index"
        >
          <div class="list-img"><img :src="item.icon" /></div>
          <div class="list-title">{{ item.title }}</div>
          <div class="list-text">{{ item.text }}</div>
        </div>
      </div>
    </div>
    <bottom></bottom>
  </div>
</template>

<script>
import bottom from "@/components/bottom.vue";
export default {
  name: "assetManagement",
  components: {
    bottom
  },
  data() {
    return {};
  },
  mounted() {
    document.body.scrollTop = 0;
    document.documentElement.scrollTop = 0;
  }
};
</script>

<style lang="scss" scoped>
.asset-wrap {
  .asset-wrap-content {
    width: 1200px;
    margin: 0 auto 100px;
    // min-height: calc(100vh - 70px);
    .image {
      width: 1200px;
      height: 480px;
      margin: 50px auto;
      img {
        width: 100%;
        height: 100%;
        object-fit: contain;
      }
    }
    .list {
      width: 24%;
      .list-img {
        width: 80px;
        height: 80px;
        margin: auto;
        display: flex;
        img {
          width: 100%;
          height: 100%;
          object-fit: contain;
        }
      }
      .list-title {
        color: #004877;
        font-size: 20px;
        margin: 5px 0;
      }
      .list-text {
        font-size: 16px;
      }
    }
  }
}
</style>

<template>
  <div class="fintech-wrap" id="fintech">
    <div class="fintech-wrap-content">
      <div class="top">
        <!-- <div class="pc" @click="goPc()">
          <div class="icon-pc">
            <img src="@/assets/image/OurOffering/icon_PC.png" />
          </div>
          Login
        </div> -->
        <div class="top-image d-flex flex-y-center flex-x-center">
          <div class="logo">
            <img src="@/assets/image/OurOffering/1690STOCL_APP_logo.png" />
          </div>
          <div class="top-search">
            <div class="search-img">
              <img src="@/assets/image/OurOffering/img_1690.png" />
            </div>
            <div class="search-app">
              <img
                @click="download(0)"
                src="@/assets/image/OurOffering/app_store.png"
              />
              <img
                @click="download(1)"
                src="@/assets/image/OurOffering/google_play.png"
              />
            </div>
          </div>
          <div class="logo">
            <img src="@/assets/image/OurOffering/download_QR_cord.png" />
          </div>
        </div>
      </div>
      <div class="title">{{ $t("OurOffering.fintechTitle") }}</div>
      <div class="text">{{ $t("OurOffering.fintechText") }}</div>
      <div
        class="textData"
        v-for="(item, index) in $t('OurOffering.fintechTextData')"
        :key="index"
      >
        <div>{{ item.title }}</div>
        <ul>
          <li v-for="(item1, index1) in item.data" :key="index1">
            {{ item1 }}
          </li>
        </ul>
      </div>
      <div class="listBg">
        <div class="listWidth d-flex flex-y-center flex-x-between">
          <div
            class="list"
            v-for="(item, index) in $t('OurOffering.fintechData')"
            :key="index"
          >
            <div class="list-img"><img :src="item.icon" /></div>
            <div class="list-title">{{ item.name }}</div>
          </div>
        </div>
      </div>
      <div class="title">FORM DOWNLOAD</div>
      <div class="bottom">
        <div class="catalogue-box">
          <div>
            <div
              class="catalogue-title"
              :class="{ 'active-bg': active === index }"
              v-for="(item, index) in $t('OurOffering.catalogueData')"
              :key="index"
              @click="changeCatalogue(index)"
            >
              {{ item.name }}
            </div>
          </div>
          <div
            v-for="(item, index) in $t('OurOffering.catalogueData')"
            :key="index"
          >
            <div v-if="active == index">
              <div
                class="pdf-box"
                v-for="(item1, index1) in item.pdfData"
                :key="index1"
              >
                <div>
                  <div class="image">
                    <img src="@/assets/image/OurOffering/icon_put_offering_05_pdf@2x.png" />
                  </div>
                </div>
                <div class="pdf-box-title" @click="openPdf(item1.http)">
                  {{ index1 + 1 }}. {{ item1.name }}
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
    <bottom></bottom>
  </div>
</template>

<script>
import bottom from "@/components/bottom.vue";
export default {
  name: "fintech",
  components: {
    bottom
  },
  data() {
    return {
      active: 0
    };
  },
  mounted() {},
  methods: {
    changeCatalogue(index) {
      this.active = index;
    },
    openPdf(url) {
      window.open(url);
    },
    download(index) {
      if (index === 0) {
        window.open("https://1690-api-app.tecklesol.com/");
      } else if (index === 1) {
        window.open(
          "https://play.google.com/store/apps/details?id=com.hk.trading"
        );
      }
    },
    goPc() {
      window.open("https://m.1690stock.com/webapp#/");
    }
  }
};
</script>

<style lang="scss" scoped>
.fintech-wrap {
  // width: 62.5vw;
  // margin: 50px auto 100px;
  .fintech-wrap-content {
    // width: 62.5vw;
    margin: 50px auto 100px;
    .top {
      width: 1200px;
      height: 470px;
      background: url("../../../assets/image/OurOffering/banner_our_offering_05.png")
        no-repeat;
      background-size: cover;
      margin: auto;
      .pc {
        display: flex;
        width: 151px;
        height: 49px;
        font-size: 20px;
        color: #ffffff;
        line-height: 49px;
        background: url("../../../assets/image/OurOffering/butten05.png") no-repeat
          no-repeat;
        background-size: 100% 100%;
        float: right;
        margin-top: 35px;
        margin-right: 45px;
        .icon-pc {
          width: 28px;
          height: 24px;
          margin: 5px 20px 0 22px;
          img {
            width: 100%;
            height: 100%;
            object-fit: contain;
          }
        }
      }
      .top-image {
        position: absolute;
        left: 21.4%;
        top: 350px;
        .logo {
          width: 105px;
          height: 105px;
          display: flex;
          img {
            width: 100%;
            height: 100%;
            object-fit: contain;
          }
        }
        .top-search {
          margin: auto 22px;
          .search-img {
            width: 303px;
            height: 55px;
            display: flex;
            img {
              width: 100%;
              height: 100%;
              object-fit: contain;
            }
          }
          .search-app {
            width: 146px;
            height: 45px;
            display: flex;
            img {
              width: 100%;
              height: 100%;
              object-fit: contain;
              margin-right: 5px;
            }
          }
        }
      }
    }
    .text {
      width: 996px;
      font-size: 16px;
      text-align: left;
      margin: auto;
    }
    .textData {
      width: 996px;
      font-size: 16px;
      text-align: left;
      margin: 30px auto 50px;
      li {
        margin-left: 25px;
      }
    }
    .listBg {
      background: #f0f0f0;
      .listWidth {
        width: 62.5vw;
        margin: auto;
        .list {
          width: 12%;
          margin: 34px auto 45px;
          // margin-top: 50px;
          .list-img {
            width: 64px;
            height: 64px;
            margin: auto;
            display: flex;
            img {
              width: 100%;
              height: 100%;
              object-fit: contain;
            }
          }
          .list-title {
            width: 10%;
            color: #004877;
            font-size: 20px;
            font-size: 500;
            height: 65px;
            padding-top: 10px;
            display: table-cell;
            vertical-align: middle;
          }
        }
      }
    }
    .title {
      font-size: 32px;
      color: #00b2e3;
      margin: 50px auto 35px;
    }

    .bottom {
      .catalogue-box {
        width: 62.5vw;
        margin: auto;
        display: flex;
        // justify-content: center;
        font-size: 20px;
        .catalogue-title {
          width: 430px;
          text-align: end;
          height: 60px;
          line-height: 60px;
          padding-right: 10px;
          margin-right: 60px;
        }
        .active-bg {
          color: #004877;
          background: #eff9ff;
        }
        .pdf-box {
          display: flex;
          margin-bottom: 30px;
          .image {
            width: 40px;
            height: 42px;
            margin-right: 23px;
            img {
              width: 100%;
              height: 100%;
              object-fit: contain;
            }
          }
          .pdf-box-title {
            margin: auto 0;
            text-align: left;
            text-decoration: underline;
            color: #004877;
          }
        }
      }
    }
  }
}
</style>

<template>
  <div class="our-offering">
    <div class="image-top">
      <img src="@/assets/image/OurOffering/banner_out_offering.png" />
    </div>
    <div class="list">
      <div class="list-img">
        <img src="@/assets/image/OurOffering/img05.png" />
      </div>
      <div class="item">
        <div class="logo-img">
          <img src="@/assets/image/OurOffering/1690LOGO.png" />
        </div>
        <div class="title">
          <span style="color: #FF5C00 ">1690 STOCK</span
          ><span style="color: #FF9E18"> FINTECH </span>
        </div>
        <div class="text stock">
          <div style="display: flex;">
            <span style="margin-right: 5px">•</span>
            <span
              >AI fintech trading platform aims to redefine the definition of
              INVESTMENT</span
            >
          </div>
        </div>
        <div class="butten butten05" @click="goDetails('fintech')">
          Learn More
        </div>
      </div>
    </div>
    <div class="list">
      <div class="item">
        <div class="title">
          <span style="color: #B79733">FIXED </span>
          <span style="color: #DEA400">INCOME</span>
        </div>
        <div class="text">
          <div style="display: flex;">
            <span style="margin-right: 5px">•</span>
            <span>DCM : Bond Issuance, Underwriting and Distribution</span>
          </div>
          <div style="display: flex;">
            <span style="margin-right: 5px">•</span>
            <span>FUTEC Fond Fund managed by our professional team</span>
          </div>
        </div>
        <div class="butten butten02" @click="goDetails('securitiesBrokerage')">
          Learn More
        </div>
      </div>
      <div class="list-img">
        <img src="@/assets/image/OurOffering/img02.png" />
      </div>
    </div>
    <div class="list">
      <div class="list-img">
        <img src="@/assets/image/OurOffering/img01.png" />
      </div>
      <div class="item">
        <div class="title">
          <span style="color: #0073BD">ASSET</span>
          <span style="color: #00B0E3"> MANAGEMENT </span>
        </div>
        <div class="text">
          <div style="display: flex;">
            <span style="margin-right: 5px">•</span>
            <span>Structured Financing and Strategic Equity Investment</span>
          </div>
          <div style="display: flex;">
            <span style="margin-right: 5px">•</span>
            <span>Doiscretionary Investment Management Services</span>
          </div>
        </div>
        <div class="butten" @click="goDetails('assetManagement')">
          Learn More
        </div>
      </div>
    </div>
    <div class="list">
      <div class="item">
        <div class="title">
          <span style="color: #587287 ">RESEARCH</span>
        </div>
        <div class="text">
          <div style="display: flex;">
            <span style="margin-right: 5px">•</span>
            <span
              >Cutting-edge knowledge and analytical skill's to bring clients
              industry-leading analysis and investment advice with core focus on
              Greater China.</span
            >
          </div>
        </div>
        <div class="butten butten04" @click="goDetails('research')">
          Learn More
        </div>
      </div>
      <div class="list-img">
        <img src="@/assets/image/OurOffering/img04.png" />
      </div>
    </div>
    <div class="list" style="margin-bottom: 150px;">
      <div class="list-img">
        <img src="@/assets/image/OurOffering/img03.png" />
      </div>
      <div class="item">
        <div class="title">
          <span style="color: #004A78">INVESTMENT </span
          ><span style="color: #0073BD"> BANKING </span>
        </div>
        <div class="text">
          <div style="display: flex;">
            <span style="margin-right: 5px">•</span>
            <span>
              Corporate finance: IPO sponsor, financial adviser, independent
              financial adviser, compliance adviser, secondary market
              fundraising, private equity and principal investments, mergers and
              acquisitions and restructuring.</span
            >
          </div>
        </div>
        <div class="butten butten03" @click="goDetails('investmentBanking')">
          Learn More
        </div>
      </div>
    </div>
    <bottom></bottom>
  </div>
</template>

<script>
import bottom from "@/components/bottom.vue";
export default {
  name: "OurOffering",
  components: {
    bottom
  },
  data() {
    return {};
  },
  methods: {
    goDetails(path) {
      this.$router.push(path);
    }
  }
};
</script>

<style lang="scss" scoped>
.our-offering {
  .image-top {
    width: 100%;
    height: 638px;
    img {
      width: 100%;
      height: 100%;
      object-fit: cover;
    }
  }

  .list {
    display: flex;
    justify-content: center;
    padding-top: 81px;
    .list-img {
      width: 460px;
      height: 421px;
      margin-right: 90px;
      img {
        width: 100%;
        height: 100%;
        object-fit: cover;
      }
    }
    .item {
      display: flex;
      flex-direction: column;
      justify-content: center;
      text-align: left;
      .logo-img {
        width: 170px;
        height: 96px;
        display: flex;
        margin-bottom: 39px;
        img {
          width: 100%;
          height: 100%;
          object-fit: contain;
        }
      }
      .title {
        font-size: 32px;
        font-weight: bold;
      }
      .text {
        margin: 40px 0;
        font-size: 18px;
        color: #333333;
        width: 550px;
      }
      .stock {
        margin: 20px 0 37px;
      }
      .butten {
        width: 151px;
        height: 49px;
        line-height: 49px;
        background: url("../../assets/image/OurOffering/butten01.png") no-repeat
          no-repeat;
        background-size: 100% 100%;
        font-size: 18px;
        color: #fff;
        text-align: center;
      }
      .butten02 {
        background: url("../../assets/image/OurOffering/butten02.png") no-repeat
          no-repeat;
        background-size: 100% 100%;
      }
      .butten03 {
        background: url("../../assets/image/OurOffering/butten03.png") no-repeat
          no-repeat;
        background-size: 100% 100%;
      }
      .butten04 {
        background: url("../../assets/image/OurOffering/butten04.png") no-repeat
          no-repeat;
        background-size: 100% 100%;
      }
      .butten05 {
        background: url("../../assets/image/OurOffering/butten05.png") no-repeat
          no-repeat;
        background-size: 100% 100%;
      }
    }
  }
}
</style>

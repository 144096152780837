<template>
  <div class="box">
    <div class="about-us-wrap" id="aboutBox">
      <div class="content" ref="scrollbox" id="scrollBox">
        <div class="image-top">
          <img src="@/assets/image/aboutUs/home_banner.png" />
        </div>
        <div class="year-title">MILESTONES</div>
        <div class="year-box">
          <div class="list">
            <div class="list-img">
              <img src="@/assets/image/aboutUs/MILESTONES_line_2016_1.png" />
            </div>
            <div class="item">
              <div class="title" style="color: #00497B">2016</div>
              <div class="text">Establishment of FUTEC Financial</div>
            </div>
          </div>
          <div class="list">
            <div class="list-img">
              <img src="@/assets/image/aboutUs/MILESTONES_line_2019_1.png" />
            </div>
            <div class="item">
              <div class="title" style="color: #00B2E3">2019</div>
              <div class="text">
                Completed the first IPO and M&A projects
              </div>
              <div class="text">
                Completed over 30 USD-denominated corporate bonds issuance (>US$
                10 bil.)
              </div>
            </div>
          </div>
          <div class="list">
            <div class="list-img">
              <img src="@/assets/image/aboutUs/MILESTONES_line_2021_1.png" />
            </div>
            <div class="item">
              <div class="title" style="color: #0090CB">2021</div>
              <div class="text">
                Launched 1690 stock web-based trading platform
              </div>
              <div class="text">
                FUTEC first inter-university investment tournament
              </div>
            </div>
          </div>
        </div>
        <div class="year-bgc"></div>
        <div class="year-box-two">
          <div class="list">
            <div class="list-img">
              <img src="@/assets/image/aboutUs/MILESTONES_line_2017.png" />
            </div>
            <div class="item">
              <div class="title" style="color: #006EA9">2017</div>
              <div class="text">
                Licensed by the Securities and Futures Comission of Hong Kong
              </div>
            </div>
          </div>
          <div class="list">
            <div class="list-img">
              <img src="@/assets/image/aboutUs/MILESTONES_line_2020.png" />
            </div>
            <div class="item">
              <div class="title" style="color: #587281">2020</div>
              <div class="text">
                Launched FUTEC High Yield Bond Fund Ranked 22th top investment
                bank in corporate bonds issuance in Hong Kong
              </div>
            </div>
          </div>
        </div>
        <!-- <div class="image">
          <img src="@/assets/image/aboutUs/img_about_us_02.png" />
        </div> -->
        <!-- <div class="milestones">
          <div class="milestones-title">{{ $t("aboutUs.milestones") }}</div>
          <div class="d-flex milestones-item">
            <div
              class="milestones-list"
              v-for="(item, index) in $t('aboutUs.milestonesList')"
              :key="index"
            >
              <div class="milestones-list-title">{{ item.year }}</div>
              <ul v-for="(item1, index1) in item.list" :key="index1">
                {{
                  item1.title
                }}
                <li v-for="(item2, index2) in item1.text" :key="index2">
                  <span class="icon">•&nbsp;</span><span>{{ item2 }}</span>
                </li>
              </ul>
            </div>
          </div>
        </div> -->
        <!-- <div class="image" :class="{ animation: isShowAnimation }">
          <img src="@/assets/image/aboutUs/img_about_us_03.png" />
        </div> -->
        <div class="management">
          <!-- :class="{ animation: isShowAnimation }" -->
          <div class="management-title">{{ $t("aboutUs.management") }}</div>
          <div
            v-if="!showDetails"
            class="d-flex flex-y-center flex-x-center flex-x-wrap"
          >
            <div
              class="management-list-image"
              v-for="(item, index) in $t('aboutUs.managementList')"
              :key="index"
              @mouseenter="senters(item)"
              @mouseleave="leaver(item)"
            >
              <div class="image" v-if="isSHowManagement">
                <img :src="item.userImage" />
              </div>
              <div
                class="management-list-mous"
                v-if="!isSHowManagement && !item.state && index == 0"
              >
                <div class="left-image">
                  <img :src="item.userImage2" />
                </div>
                <div class="right-box">
                  <div class="title">{{ item.position }}</div>
                  <div class="title1">{{ item.name }}</div>
                  <div class="text">
                    {{ item.text }}
                  </div>
                </div>
              </div>
              <div
                class="management-list-mous"
                v-if="!isSHowManagement && !item.state && index == 1"
              >
                <div class="right-box">
                  <div class="title title-right">{{ item.position }}</div>
                  <div class="title1 title-right">{{ item.name }}</div>
                  <div class="text">
                    {{ item.text }}
                  </div>
                </div>
                <div class="left-image">
                  <img :src="item.userImage2" />
                </div>
              </div>
            </div>
          </div>
        </div>
        <div class="image">
          <!-- :class="{ animation2: isShowAnimation2 }" -->
          <img src="@/assets/image/aboutUs/img_about_us_04.png" />
        </div>
        <div class="corporate-culture">
          <!-- :class="{ animation2: isShowAnimation2 }" -->
          <div class="corporate-culture-title">
            {{ $t("aboutUs.corporateCulture") }}
          </div>
          <div class="corporate-culture-p">{{ $t("aboutUs.text") }}</div>
          <div class="d-flex flex-x-between">
            <div
              class="corporate-culture-list"
              v-for="(item, index) in $t('aboutUs.corporateCultureList')"
              :key="index"
            >
              <div>
                <div class="corporate-culture-image">
                  <img :src="item.image" />
                </div>
                <div class="corporate-culture-list-title">{{ item.title }}</div>
              </div>
              <div class="corporate-culture-list-text">
                {{ item.text }}
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
    <bottom></bottom>
  </div>
</template>

<script>
import bottom from "@/components/bottom.vue";
export default {
  name: "AboutUs",
  components: {
    bottom
  },
  data() {
    return {
      showDetails: false,
      isShowAnimation: false,
      isShowAnimation2: false,
      isShow: false,
      isSHowManagement: true
    };
  },
  computed: {},
  mounted() {
    document.body.scrollTop = 0;
    document.documentElement.scrollTop = 0;
    // window.addEventListener("scroll", this.handleScroll, true);
  },
  methods: {
    senters(item) {
      this.$nextTick(() => {
        item.state = false;
        this.isSHowManagement = false;
      });
    },
    leaver(item) {
      // this.$nextTick(() => {
      item.state = true;
      this.isSHowManagement = true;
      // });
    },
    handleScroll(e) {
      if (e.target.scrollTop > 400) {
        this.isShowAnimation = true;
      }
      if (e.target.scrollTop > 1400) {
        this.isShowAnimation2 = true;
      }
    },
    sentersCulture() {
      this.isShow = true;
    },
    leaverCulture() {
      this.isShow = false;
    }
  }
};
</script>

<style lang="scss" scoped>
.box {
  width: 100%;
  // height: 100%;
  // width: 62.5vw;
  // margin: auto;
  // background: #fff;
  // box-shadow: 8px 0 10px 0px rgba(0, 0, 0, 0.1) inset;
  .about-us-wrap {
    margin: auto;
    // background: #fff;
    // min-width: 1200px !important;
    .content {
      overflow-y: auto;
      .image-top {
        width: 100%;
        height: 638px;
        img {
          width: 100%;
          height: 100%;
          object-fit: contain;
        }
      }
      .image {
        width: 1200px;
        height: 480px;
        margin: 50px auto 69px;
        img {
          width: 100%;
          height: 100%;
          object-fit: contain;
        }
      }
      @media screen and (max-width: 1200px) {
        .image {
          width: 90%;
          height: 100%;
        }
      }

      .animation {
        -webkit-animation: 5s ease;
        -moz-animation: 5s ease;
        -ms-animation: 5s ease;
        animation: 5s ease;
        -webkit-animation-name: fadeinB;
        -moz-animation-name: fadeinB;
        -ms-animation-name: fadeinB;
        animation-name: fadeinB;
      }
      .animation2 {
        -webkit-animation: 5s ease;
        -moz-animation: 5s ease;
        -ms-animation: 5s ease;
        animation: 5s ease;
        -webkit-animation-name: fadeinB;
        -moz-animation-name: fadeinB;
        -ms-animation-name: fadeinB;
        animation-name: fadeinB;
      }
      /* 淡入-从下 */
      @-webkit-keyframes fadeinB {
        0% {
          opacity: 0;
          -webkit-transform: translateY(100px);
        }
        100% {
          opacity: 1;
          -webkit-transform: translateY(0);
        }
      }
      @-moz-keyframes fadeinB {
        0% {
          opacity: 0;
          -moz-transform: translateY(100px);
        }
        100% {
          opacity: 1;
          -moz-transform: translateY(0);
        }
      }
      @-ms-keyframes fadeinB {
        0% {
          opacity: 0;
          -ms-transform: translateY(100px);
        }
        100% {
          opacity: 1;
          -ms-transform: translateY(0);
        }
      }
      @keyframes fadeinB {
        0% {
          opacity: 0;
          transform: translateY(100px);
        }
        100% {
          opacity: 1;
          transform: translateY(0);
        }
      }
      .year-title {
        font-size: 32px;
        font-weight: 500;
        color: #00b2e3;
        margin-bottom: 50px;
      }
      .year-box {
        width: 1200px;
        margin: auto;
        display: flex;
        justify-content: space-around;
        .list {
          display: flex;
          text-align: left;
          .list-img {
            width: 21px;
            height: 277px;
            display: flex;
            img {
              width: 100%;
              height: 100%;
              object-fit: cover;
            }
          }
          .item {
            margin-left: 13px;
            .title {
              font-size: 32px;
              font-weight: bold;
              color: #00497b;
            }
            .text {
              // width: 331px;
              width: 350px;
              font-size: 18px;
              font-weight: 400;
              color: #333333;
            }
          }
        }
      }
      .year-bgc {
        height: 20px;
        width: 1291px;
        margin: -20px auto 0;
        background: url("../../../assets/image/aboutUs/MILESTONES_line_1.png")
          no-repeat;
      }
      .year-box-two {
        display: flex;
        width: 1200px;
        margin: -20px auto 0;
        display: flex;
        justify-content: center;
        .list {
          display: flex;
          text-align: left;
          .list-img {
            width: 21px;
            height: 277px;
            display: flex;
            img {
              width: 100%;
              height: 100%;
              object-fit: cover;
            }
          }
          .item {
            margin-left: 13px;
            margin-top: 37px;
            .title {
              font-size: 32px;
              font-weight: bold;
              color: #00497b;
            }
            .text {
              // width: 331px;
              width: 350px;
              font-size: 18px;
              font-weight: 400;
              color: #333333;
            }
          }
        }
      }
      .milestones {
        color: #333333;
        // font-size: 12px;
        // padding: 0 360px;
        // margin: 20px 0;
        // min-width: 1200px !important;
        width: 1200px;
        margin: auto;
        .milestones-title {
          font-size: 32px;
          font-weight: 500;
          color: #00b2e3;
          // margin-top: 30px;
          // margin-bottom: 20px;
          margin-bottom: 30px;
        }
        .milestones-list {
          background-color: #f0f0f0;
          border-radius: 10px;
          margin-right: 16px;
          box-sizing: border-box;
          width: 227px;
          flex: 1;
          width: 100%;
          text-align: left;
          padding-bottom: 22px;
          .milestones-list-title {
            color: #00b2e3;
            font-size: 32px;
            font-weight: 500;
            margin-left: 20px;
            margin-top: 20px;
          }
          ul {
            margin-left: 20px;
            font-size: 16px;
            padding-right: 5px;
            li {
              list-style: none;
              display: flex;
              margin-left: -15px;
              .icon {
                font-size: 24px;
                margin-top: -5px;
              }
            }
          }
        }
      }
      @media screen and (max-width: 1000px) {
        .milestones {
          padding: 0 5%;
          width: 90%;

          .milestones-item {
            // display: inline;
            // width: 80%;
            flex-direction: column;
            flex-wrap: wrap;

            .milestones-list {
              padding-left: 5%;
              // width: 90%;
              // width: 1000px;
              margin-top: 30px;

              .milestones-list-title {
                padding-top: 20px;
              }
              ul {
                padding-bottom: 10px;
              }
            }
          }
        }
      }
      .management {
        .management-title {
          font-size: 32px;
          font-weight: 500;
          margin-top: 59px;
          color: #00b2e3;
        }
        .management-list {
          height: 400px;
          width: 290px;
          // height: 45vh;
          // width: 25%;
          margin: 2% 4% 0;
          border-radius: 5%;
          box-shadow: 0px 0px 16px 0px rgba(0, 0, 0, 0.1);
          .management-list-image {
            width: 181px;
            height: 181px;
            margin: auto;
            margin-top: 42px;
            img {
              width: 100%;
              height: 100%;
              object-fit: contain;
            }
          }
          .management-list-position {
            font-size: 18px;
            color: #004877;
            margin-top: 40px;
          }
          .management-list-name {
            font-size: 18px;
            color: #000000;
            margin: 5px auto;
          }
          .management-list-icon {
            width: 23px;
            height: 23px;
            margin: 5px auto;
            display: flex;
            img {
              width: 100%;
              height: 100%;
              object-fit: contain;
            }
          }
          .management-list1-position1 {
            color: #004877;
            font-size: 18px;
            font-weight: 500;
            margin-top: 20px;
          }
          .management-list1-name {
            font-size: 18px;
            color: #000000;
            font-weight: 500;
          }
          .management-list1-text {
            font-size: 12px;
            color: #333333;
            margin: 10px 15px;
            text-align: left;
          }
        }
        .management-list-image {
          display: flex;
          justify-content: center;
          margin-top: 60px;
          .image {
            width: 309px;
            height: 393px;
            margin: auto 30px;
            img {
              width: 100%;
              height: 100%;
              object-fit: contain;
            }
          }
        }
        .management-list-mous {
          display: flex;
          justify-content: center;
          .left-image {
            width: 309px;
            height: 394px;
            img {
              width: 100%;
              height: 100%;
              object-fit: contain;
            }
          }
          .right-box {
            text-align: left;
            margin: auto 5px;
            .title {
              font-size: 36px;
              font-weight: 400;
              color: #00497b;
            }
            .title1 {
              font-size: 36px;
              font-weight: 400;
              color: #000000;
              margin-bottom: 5px;
            }
            .title-right {
              text-align: right;
            }
            .text {
              width: 797px;
              height: 182px;
              font-size: 14px;
              font-weight: 400;
              color: #333333;
              line-height: 28px;
            }
          }
        }
      }
      .corporate-culture {
        // margin-bottom: 126px;
        width: 1200px;
        margin: auto;
        margin-bottom: 126px;
        .corporate-culture-title {
          // margin-left: 10px;
          // margin-top: 30px;
          color: #00b2e3;
          font-size: 32px;
          font-weight: 500;
        }
        .corporate-culture-p {
          font-size: 18px;
          color: #004877;
        }
        .corporate-culture-list {
          width: 25%;
          margin-top: 10px;
          .corporate-culture-image {
            width: 138px;
            height: 138px;
            margin: auto;
            display: flex;
            img {
              width: 100%;
              height: 100%;
              object-fit: contain;
            }
          }
          .corporate-culture-list-title {
            height: 45px;
            line-height: 24px;
            color: #004877;
            font-size: 20px;
            margin-top: 10px;
          }
          .corporate-culture-list-text {
            font-size: 16px;
            color: #333333;
            margin-top: 10px;
            text-align: left;
          }
        }
      }
      @media screen and (max-width: 1000px) {
        .corporate-culture {
          width: 90%;
        }
      }
      .animation2 {
        -webkit-animation: 5s ease;
        -moz-animation: 5s ease;
        -ms-animation: 5s ease;
        animation: 5s ease;
        -webkit-animation-name: fadeinB;
        -moz-animation-name: fadeinB;
        -ms-animation-name: fadeinB;
        animation-name: fadeinB;
      }
      /* 淡入-从下 */
      @-webkit-keyframes fadeinB {
        0% {
          opacity: 0;
          -webkit-transform: translateY(100px);
        }
        100% {
          opacity: 1;
          -webkit-transform: translateY(0);
        }
      }
      @-moz-keyframes fadeinB {
        0% {
          opacity: 0;
          -moz-transform: translateY(100px);
        }
        100% {
          opacity: 1;
          -moz-transform: translateY(0);
        }
      }
      @-ms-keyframes fadeinB {
        0% {
          opacity: 0;
          -ms-transform: translateY(100px);
        }
        100% {
          opacity: 1;
          -ms-transform: translateY(0);
        }
      }
      @keyframes fadeinB {
        0% {
          opacity: 0;
          transform: translateY(100px);
        }
        100% {
          opacity: 1;
          transform: translateY(0);
        }
      }
    }
  }
}
</style>

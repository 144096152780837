<template>
  <div class="research-wrap">
    <div class="research-content">
      <div class="image">
        <div class="title">FUTEC Research</div>
        <div class="text">
          Core focus on China, delivering high quality company specific, sector
          and economic insights and reports to our clients worldwide.
        </div>
      </div>
      <div class="content-text">
        <div>
          We are committed to maintaining the highest level of independence and
          objectivity in our insights and reports.
        </div>
      </div>
      <div class="content-title">
        Our areas of expertise include but are not limited to :
      </div>
      <div class="content-list">
        <div class="list">
          <div class="list-img">
            <img src="../../../assets/image/OurOffering/img_research0102.png" />
          </div>
          <div class="title" style="color: #0081DF">CONSUMER</div>
        </div>
        <div class="list">
          <div class="list-img">
            <img src="../../../assets/image/OurOffering/img_research0101.png" />
          </div>
          <div class="title" style="color: #587281">EDUCATION</div>
        </div>
        <div class="list">
          <div class="list-img">
            <img src="../../../assets/image/OurOffering/img_research0103.png" />
          </div>
          <div class="title" style="color: #00AADD">HEALTHCARE</div>
        </div>
        <div class="list">
          <div class="list-img">
            <img src="../../../assets/image/OurOffering/img_research0104.png" />
          </div>
          <div class="title" style="color: #004A78">REAL ESTATE</div>
        </div>
      </div>
      <div class="content-title">
        OUR KEY RESEARCH PRODUCTS INCLUDE
      </div>
      <div class="content-bottom">
        <div class="left">
          <div>
            <span>LOCAL RESEARCH AND</span>
            <span>INVESTMENT RECOMMENDATION REPORT</span>
            <span>( HK&US LISTED )</span>
          </div>
        </div>
        <div class="centre"><span>MONTHLY SECTOR REPORTS</span></div>
        <div class="right"><span>TRADING OPPORTUNITY NOTES</span></div>
      </div>
      <!-- <div class="content">
        <div>{{ $t("OurOffering.research.title") }}</div>
        <div
          class="text"
          v-for="(item, index) in $t('OurOffering.research.text')"
          :key="index"
        >
          <div>{{ item.title }}</div>
          <ul>
            <li v-for="(item1, index1) in item.text" :key="index1">
              {{ item1 }}
            </li>
          </ul>
        </div>
      </div> -->
    </div>
    <bottom></bottom>
  </div>
</template>

<script>
import bottom from "@/components/bottom.vue";
export default {
  name: "research",
  components: {
    bottom
  },
  mounted() {
    document.body.scrollTop = 0;
    document.documentElement.scrollTop = 0;
  }
};
</script>

<style lang="scss" scoped>
.research-wrap {
  .research-content {
    margin-bottom: 210px;
    .image {
      width: 1233px;
      height: 480px;
      margin: 60px auto 50px;
      background: url("../../../assets/image/OurOffering/img_research01.png")
        no-repeat no-repeat;
      background-size: 100% 100%;
      .title {
        // width: 384px;
        font-size: 50px;
        font-weight: bold;
        color: #ffffff;
        margin-left: 671px;
        padding-top: 150px;
        text-align: left;
      }
      .text {
        width: 498px;
        font-size: 30px;
        font-weight: 400;
        color: #dbffff;
        line-height: 40px;
        text-align: left;
        margin-left: 671px;
        margin-top: 55px;
      }
    }
    .content-text {
      margin: auto;
      width: 1233px;
      height: 177px;
      font-size: 28px;
      font-weight: 400;
      color: #009ec8;
      background: url("../../../assets/image/OurOffering/img_research01_bg.png")
        no-repeat no-repeat;
      background-size: 100% 100%;
      div {
        // width: 667px;
         width: 770px;
        margin: auto;
        padding-top: 50px;
        text-align: left;
      }
    }
    .content-title {
      font-size: 18px;
      font-weight: 400;
      color: #333333;
      line-height: 32px;
      margin-top: 60px;
    }
    .content-list {
      margin: auto 404px;
      display: flex;
      justify-content: space-between;
      .list {
        .list-img {
          width: 210px;
          height: 210px;
          margin: 65px auto 60px;
          img {
            width: 100%;
            height: 100%;
            object-fit: contain;
          }
        }
        .title {
          font-size: 22px;
          font-weight: bold;
        }
      }
    }
    .content-bottom {
      margin: 50px 404px 0;
      display: flex;
      justify-content: space-between;
      div {
        width: 338px;
        height: 339px;
        font-size: 15px;
        font-weight: 400;
        color: #ffffff;
      }
      .left {
        background: url("../../../assets/image/OurOffering/img_research0105.png")
          no-repeat no-repeat;
        background-size: 100% 100%;
        div {
          display: block;
          padding-top: 270px;
          span {
            display: block;
          }
        }
      }
      .centre {
        background: url("../../../assets/image/OurOffering/img_research0106.png")
          no-repeat no-repeat;
        background-size: 100% 100%;
        span {
          display: block;
          padding-top: 289px;
        }
      }
      .right {
        background: url("../../../assets/image/OurOffering/img_research0107.png")
          no-repeat no-repeat;
        background-size: 100% 100%;
        span {
          display: block;
          padding-top: 289px;
        }
      }
    }
    .content {
      font-size: 16px;
      width: 985px;
      margin: auto;
      text-align: left;
      .text {
        margin-top: 50px;
        ul {
          margin-left: 30px;
        }
      }
    }
  }
}
</style>

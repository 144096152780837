<template>
  <div class="media">
    <div class="image">
      <img src="@/assets/image/banner_media.png" />
    </div>
    <div class="content">
      <!-- <div class="title">MEDIA</div> -->
      <div class="box">
        <div class="left">
          <div class="title">ARCHIVE</div>
          <div class="item-box">
            <div
              class="item"
              :class="{ titleC: cueerntYear === index }"
              v-for="(item, index) in yearData"
              :key="index"
              @click="onChangeYear(item, index)"
            >
              {{ item }}
            </div>
          </div>
        </div>
        <div class="right">
          <div class="year">{{ year }}</div>
          <div
            class="list"
            v-for="(item, index) in newsData[cueerntYear]"
            :key="index"
          >
            <!-- <div class="right-img"><img :src="item.image" /></div> -->
            <div class="data">
              <div class="month">{{ item.month }}</div>
              <div class="day">{{ item.day }}</div>
            </div>
            <div class="text">
              {{ item.newsTitle }} &nbsp;
              <a :href="item.href" target="_media"> &gt;&gt; </a>
            </div>
          </div>
        </div>
      </div>
    </div>
    <bottom></bottom>
  </div>
</template>

<script>
import bottom from "@/components/bottom.vue";
export default {
  name: "Media",
  components: {
    bottom
  },
  data() {
    return {
      news: "",
      year: 2022,
      yearData: [2022, 2021, 2020, 2019, 2018, 2017],
      newsData: [
        [
          {
            month: "APR",
            day: "27",
            newsTitle:
              "Strategic partnership between FUTEC Financial Limited and ARTA Global Markets Limited",
            href: "http://www.futec.com/Doc/Strategic%20partnership%20between%20FUTEC%20Financial%20Limited%20and%20ARTA%20Global%20Markets%20Limited.pdf"
          }
        ],
        [
          {
            month: "DEC",
            day: "16",
            newsTitle:
              "Edvantage Group (382.HK) New vocational M&A, in-line with favourable policy",
            href: "https://mp.weixin.qq.com/s/YUsk6I7jGXi-qIKKjfbBlw"
          },
          {
            month: "DEC",
            day: "02",
            newsTitle:
              "Café de Coral (341.HK) Recovery slower than expected but in right direction",
            href: "https://mp.weixin.qq.com/s/yCnCbCh9LoJeAJOBmrpzUA"
          },
          {
            month: "DEC",
            day: "01",
            newsTitle:
              "EC Healthcare (2138.HK)Strong 1H22, still multiple share price catalysts ahead",
            href: "https://mp.weixin.qq.com/s/pZCqEM1ptoPhH3o9qsXudQ"
          },
          {
            month: "NOV",
            day: "30",
            newsTitle:
              "FUTEC Financial has been appointed as the Independent Financial Adviser to advise the Independent Board Committee and the Independent Shareholders on connected transaction in relation to subscription of new shares by Joy Pacific under Specific Mandate",
            href: "https://mp.weixin.qq.com/s/ppXyor7yU9cNin9IeObyag"
          },
          {
            month: "NOV",
            day: "24",
            newsTitle:
              "Huali University Group (1756.HK) Can only get better onwards from here",
            href: "https://mp.weixin.qq.com/s/7Uk6kwYM5ocn-jllxP0aNg"
          },
          {
            month: "NOV",
            day: "17",
            newsTitle:
              "Edvantage Group (382.HK)Valuation attractive, strong FY21 and growth outlook",
            href: "https://mp.weixin.qq.com/s/jzH2EiellqLWw_TbEOWMjA"
          },
          {
            month: "NOV",
            day: "09",
            newsTitle:
              "FUTEC Financial completed US$158 million Senior Notes issuance for Ganglong China Property Group Limited (6968.HK). ",
            href: "https://mp.weixin.qq.com/s/8YV4gcWHI7aeAD5DR_JLaw"
          },
          {
            month: "NOV",
            day: "01",
            newsTitle:
              "Public offering is now open for Better Home (1491.HK) !",
            href: "https://mp.weixin.qq.com/s/KmSPj3AR3JB7PQJa16PspA"
          },
          {
            month: "OCT",
            day: "28",
            newsTitle:
              "ECH (2138 HK) Demand undeterred despite border still closed",
            href: "https://mp.weixin.qq.com/s/E41gevKeLZcm_GmHxXcbVw"
          },
          {
            month: "SEP",
            day: "14",
            newsTitle:
              "FUTEC Financial completed US$300 million Senior Notes issuance for Radiance Holdings (Group) Company Limited (9993.HK).",
            href: "https://mp.weixin.qq.com/s/qnqXeNwL2SSuJtmC0NoEPA"
          },
          {
            month: "SEP",
            day: "07",
            newsTitle:
              "FUTEC Financial completed US$375 million Senior Notes issuance for Sun Hung Kai & Co. Limited (0086.HK).",
            href: "https://mp.weixin.qq.com/s/AP1ZiYORkUQwnWIO0GAUPA"
          },
          {
            month: "AUG",
            day: "31",
            newsTitle:
              "FUTEC Financial has been appointed by Steve Leung Design Group Limited as the Independent Financial Adviser to advise the Independent Board Committee and the Independent Shareholders on continuing connected transactions",
            href: "https://mp.weixin.qq.com/s/I3K0lx_p8mPxsJmw6vLY9A"
          },
          {
            month: "AUG",
            day: "30",
            newsTitle:
              "FUTEC Financial completed US$400 million Senior Notes issuance for Agile Group Holdings Limited (3383.HK).",
            href: "https://mp.weixin.qq.com/s/AHO1ggJCCfaYIe-E6Nf_Rg"
          },
          {
            month: "AUG",
            day: "26",
            newsTitle:
              "Haidilao International (6862.HK)Profitability returns but future growth visibility low",
            href: "https://mp.weixin.qq.com/s/AC8WKblr82jrHM7tvjSUxA"
          },
          {
            month: "AUG",
            day: "25",
            newsTitle:
              "China Lilang (1234.HK)Worst is over cheap valuation and high dividend yield",
            href: "https://mp.weixin.qq.com/s/gGsbCYnUdewpO8T1OSIGOg"
          },
          {
            month: "AUG",
            day: "24",
            newsTitle:
              "Xtep International (1368.HK)Strong 1H21 expect even stronger results ahead",
            href: "https://mp.weixin.qq.com/s/nkRBKaMfAMiIEbY_A7smdA"
          },
          {
            month: "AUG",
            day: "16",
            newsTitle:
              "Times Neighborhood (9928.HK)Strong 1H21 and growth outlook valuation attractive",
            href: "https://mp.weixin.qq.com/s/OQFPclk-DU8oY85PWEC7cQ"
          },
          {
            month: "AUG",
            day: "10",
            newsTitle:
              "FUTEC Financial has been appointed as the Independent Financial Adviser to advise the Independent Board Committee and the Independent Shareholders on revision of annual caps for continuing connected transactions; continuing connected transaction in relation to general health and wellness services",
            href: "https://mp.weixin.qq.com/s/LOPL-Ff5yWtNQ3hiDus4Nw"
          },
          {
            month: "JUL",
            day: "16",
            newsTitle:
              "HeungKong Financial completed US$314 million Senior Notes issuance for Agile Group Holdings Limited (3383.HK).",
            href: "https://mp.weixin.qq.com/s/waC6X8CA7iBMLtOqCu3rGw"
          },
          {
            month: "JUL",
            day: "05",
            newsTitle:
              "EC Healthcare (2138.HK)Growth ahead via HK recovery/PRC expansion/M&A",
            href: "https://mp.weixin.qq.com/s/fGbt4o-O7rouC1hCRCJglQ"
          },
          {
            month: "JUN",
            day: "29",
            newsTitle:
              "HeungKong Financial has been appointed as the Independent Financial Adviser on connected transaction- entering into supplemental deed for the extension of time for the fulfilment of profit guarantee",
            href: "https://mp.weixin.qq.com/s/-5V7-K2MwfigMeAvgLlSZQ"
          },
          {
            month: "JUN",
            day: "24",
            newsTitle:
              "HeungKong Financial completed US$200 million Senior Notes issuance for Hopson Development Holdings Limited (754.HK).",
            href: "https://mp.weixin.qq.com/s/7AW87bTh5eXwIPZu7lVVtA"
          },
          {
            month: "JUN",
            day: "23",
            newsTitle:
              "Café de Coral (341.HK) Maintain Buy on hk recovery and PRC expansion",
            href: "https://mp.weixin.qq.com/s/DXR_Dfk5swHEjtCAeY-oOg"
          },
          {
            month: "JUN",
            day: "08",
            newsTitle:
              "HeungKong Financial acted as the Independent Financial Adviser to Bawang International (Group) Holding Limited on its Discloseable and Connected Transactions",
            href: "https://mp.weixin.qq.com/s/Q72NzLZ8ETfkxwg8PH3HSw"
          },
          {
            month: "MAY",
            day: "28",
            newsTitle:
              "HeungKong Financial completed US$400 million Senior Notes issuance for Times China Holdings Limited (1233.HK).",
            href: "https://mp.weixin.qq.com/s/dhXj9RPBxxcztLyDmnIvsA"
          },
          {
            month: "MAY",
            day: "12",
            newsTitle:
              "HeungKong Financial completed US$300 million Senior Notes issuance for Hopson Development Holdings Limited (754.HK).",
            href: "https://mp.weixin.qq.com/s/CGAg6DunlbWGyy63K5O6CA"
          },
          {
            month: "MAY",
            day: "11",
            newsTitle:
              "HeungKong Financial completed US$300 million Senior Notes issuance for Agile Group Holdings Limited (3383.HK).",
            href: "https://mp.weixin.qq.com/s/c85xS5Q3YcB2_D2-XA_yeg"
          },
          {
            month: "APR",
            day: "28",
            newsTitle:
              "Hailiang Education Group (HLG US) New K12 M&A drives growth expect more ahead",
            href: "https://mp.weixin.qq.com/s/SIhwReP5fE26t55g3EBdmA"
          },
          {
            month: "APR",
            day: "26",
            newsTitle:
              "UMH(2138.HK) Strong potential outlook in both HK and Mainland",
            href: "https://mp.weixin.qq.com/s/s5f2CjtX8zWFGnPuHeS6UQ"
          },
          {
            month: "APR",
            day: "21",
            newsTitle:
              "Edvantage Group (382.HK) 1H21 profit surge indicating strong 2H21E and beyond",
            href: "https://mp.weixin.qq.com/s/IcEflhlYmKjJH9t9CqWY8A"
          },
          {
            month: "APR",
            day: "20",
            newsTitle:
              "Huali University Group (1756 HK) Waiting for student numbers to ramp up",
            href: "https://mp.weixin.qq.com/s/s_jbp6cvTUgcvFrT-lEYAQ"
          },
          {
            month: "APR",
            day: "08",
            newsTitle:
              "HeungKong Financial completed US$220 million Senior Sustainability Notes issuance for Redco Properties Group Limited.",
            href: "https://mp.weixin.qq.com/s/l3lZDhNv0tHU5UfGTs4Wpw"
          },
          {
            month: "MAR",
            day: "22",
            newsTitle:
              "China Lilang (1234.HK) Risk/reward attractive on post-COVID turnaround",
            href: "https://mp.weixin.qq.com/s/qWH12D4KZ57dKG8pUm13Zw"
          },
          {
            month: "MAR",
            day: "19",
            newsTitle:
              "Xtep International (1368.HK) Worst is over expect earnings growth to resume",
            href: "https://mp.weixin.qq.com/s/EvL6aApeZzTWiAxeYud69A"
          },
          {
            month: "MAR",
            day: "15",
            newsTitle:
              "Times Neighborhood (9928.HK) Deeply undervalued given strong growth outlook",
            href: "https://mp.weixin.qq.com/s/5HrUcD3Knvlte2zXV0eo5A"
          },
          {
            month: "MAR",
            day: "12",
            newsTitle:
              "Hailiang Education Group (HLG US) 1H21 results continue to indicate a strong FY21E",
            href: "https://mp.weixin.qq.com/s/ic5H_zpb4grVc8NLjNyk7Q"
          },
          {
            month: "MAR",
            day: "05",
            newsTitle:
              "HeungKong Financial completed US$175 million Senior Notes issuance for China South City Holdings Limited (1668.HK).",
            href: "https://mp.weixin.qq.com/s/PU4f4deOxEQGS78cdETJLg"
          },
          {
            month: "MAR",
            day: "03",
            newsTitle:
              "HeungKong Financial completed US$325 million Senior Notes issuance for Guangzhou R&F Properties Co. Ltd.",
            href: "https://mp.weixin.qq.com/s/lEoCdhhe6XAO9O7Hp8eilQ"
          },
          {
            month: "FEB",
            day: "05",
            newsTitle:
              "HeungKong Financial assisted JBM (HEALTHCARE) LIMITED (2161.HK) successfully listing on HKEX",
            href: "https://mp.weixin.qq.com/s/j-0a_O77tG-QXyCbKn382w"
          },
          {
            month: "FEB",
            day: "04",
            newsTitle:
              "UMH (2138.HK)Strong 3Q sales volume positive outlook post-COVID",
            href: "https://mp.weixin.qq.com/s/PN-YymtxCq9mL2HJjJQz4Q"
          },
          {
            month: "FEB",
            day: "03",
            newsTitle:
              "Huali University Group (1756.HK) Highly undervalued education operator in the GBA",
            href: "https://mp.weixin.qq.com/s/wKUFLt6A6lXO5a5K1CBhbQ"
          },
          {
            month: "JAN",
            day: "29",
            newsTitle:
              "HeungKong Financial completed US$148 million Senior Notes tap issuance for Modern Land (China) Co. Limited (1107.HK).",
            href: "https://mp.weixin.qq.com/s/gw45S2oTQp6xEThaT6AqTw"
          },
          {
            month: "JAN",
            day: "28",
            newsTitle:
              "HeungKong Financial assisted E-Star Commerical Management Company Limited Limited successfully listing on HKEX",
            href: "https://mp.weixin.qq.com/s/c_HRLcMgdrMbCL-x6vYesg"
          },
          {
            month: "JAN",
            day: "27",
            newsTitle:
              "HeungKong Financial completed US$500 million Senior Notes issuance for Guangzhou R&F Properties Co. Ltd.",
            href: "https://mp.weixin.qq.com/s/Gu25T6PQGE2ircpgbW7TBA"
          },
          {
            month: "JAN",
            day: "19",
            newsTitle:
              "HeungKong Financial completed US$250 million Senior Notes issuance for Sinic Holdings (Group) Company Limited (2103.HK).",
            href: "https://mp.weixin.qq.com/s/r0VeGrUDv81Takowc7Px1Q"
          },
          {
            month: "JAN",
            day: "15",
            newsTitle:
              "HeungKong Financial completed issuance of US$180 million Senior Notes for Dafa Properties Group Limited (6111.HK).",
            href: "https://mp.weixin.qq.com/s/831M-JgQipR5qW20CVTybg"
          },
          {
            month: "JAN",
            day: "14",
            newsTitle:
              "HeungKong Financial completed US$350 million Senior Notes issuance for Times China Holdings Limited (1233.HK). ",
            href: "https://mp.weixin.qq.com/s/Uj9Ih4GsPmJZ64zAl2bE5w"
          },
          {
            month: "JAN",
            day: "13",
            newsTitle:
              "HeungKong Financial completed US$85 million Senior Notes tap issuance for Golden Wheel Tiandi Holdings Company Limited (1232.HK)",
            href: "https://mp.weixin.qq.com/s/sMY3nZM-0d3l92BKDiN6rg"
          },
          {
            month: "JAN",
            day: "12",
            newsTitle:
              "HeungKong Financial completed US$120 million Senior Notes tap issuance for China South City Holdings Limited (1668.HK).",
            href: "https://mp.weixin.qq.com/s/bRUOqzKPhhrouH5TuzupUA"
          },
          {
            month: "JAN",
            day: "11",
            newsTitle:
              "HeungKong Financial completed US$400 million Senior Notes issuance for Hopson Development Holdings Limited (754.HK).",
            href: "https://mp.weixin.qq.com/s/lHddnEehHARVxQqhXtJdvQ"
          },
          {
            month: "JAN",
            day: "08",
            newsTitle:
              "HeungKong Financial completed US$250 million Senior Notes issuance for Modern Land (China) Co. Limited (1107.HK).",
            href: "https://mp.weixin.qq.com/s/v89ivf__5Ta85JRsJDe3Uw"
          }
        ],
        [
          {
            month: "DEC",
            day: "15",
            newsTitle:
              " HeungKong Financial completed tap issuance of US$80 million Senior Notes for Dafa Properties Group Limited (6111.HK). ",
            href: "https://mp.weixin.qq.com/s/waWJR6Mjj7INrGxKeLNZRQ"
          },
          {
            month: "NOV",
            day: "27",
            newsTitle:
              "HeungKong Financial completed US$150 million Senior Notes issuance for Ganglong China Property Group Limited (6968.HK).",
            href: "https://mp.weixin.qq.com/s/1AdPaRd5QNLLCeq6m93UWQ"
          },
          {
            month: "NOV",
            day: "18",
            newsTitle:
              "Big applause for Kingsley Leung, Chairman of Uni-Bio Science Group (00690.HK), winning this year Young Industrialist Awards of Hong Kong.",
            href: "https://mp.weixin.qq.com/s/9hUwwy1Rae5uOoKAO-KzHw"
          },
          {
            month: "NOV",
            day: "16",
            newsTitle:
              "HeungKong Financial completed US$360 million Senior Notes issuance for Guangzhou R&amp;F Properties Co. Ltd.",
            href: "https://mp.weixin.qq.com/s/I03PrMdeKAUtJ4axBTqzag"
          },
          {
            month: "NOV",
            day: "13",
            newsTitle:
              " HeungKong Financial completed US$266 million Senior Notes issuance for Redco Properties Group Limited",
            href: "https://mp.weixin.qq.com/s/i3BawKCa240O_nKZ_a2WVg"
          },
          {
            month: "NOV",
            day: "04",
            newsTitle: "Refund Arrangement of halted Ant Group IPO",
            href: "https://mp.weixin.qq.com/s/_dy3EKwuUy-MDxyA1PPdCw"
          },
          {
            month: "NOV",
            day: "02",
            newsTitle:
              "HeungKong Financial completed US$20 million Senior Notes Tap issuance for LVGEM (China) Real Estate Investment Company Limited",
            href: "https://mp.weixin.qq.com/s/SUsU42dsYSWuv5OtiRea_w"
          },
          {
            month: "OCT",
            day: "30",
            newsTitle:
              "HeungKong Financial assisted KWG Living Group Holdings Limited (3913.HK) successfully listing on HKEX",
            href: "https://mp.weixin.qq.com/s/-946Kb6dy48vUVLJuNW6wA"
          },
          {
            month: "SEP",
            day: "28",
            newsTitle:
              "HeungKong Financial completed US$330 million Senior Notes issuance for China Fortune Land Development Co., Ltd ",
            href: "https://mp.weixin.qq.com/s/b5czyN_57xzq9uuCNeochQ"
          },
          {
            month: "SEP",
            day: "04",
            newsTitle:
              "HeungKong Financial completed US$250 million Senior Notes issuance for China South City Holdings Limited",
            href: "https://mp.weixin.qq.com/s/qkcEWCvyKPhVndKo0-mWFA"
          },
          {
            month: "AUG",
            day: "13",
            newsTitle:
              " HeungKong Financial completed US$300 million Senior Notes issuance for Redco Properties Group Limited",
            href: "https://mp.weixin.qq.com/s/4JZDJOicZBVvilw3HzbtCw"
          },
          {
            month: "AUG",
            day: "07",
            newsTitle:
              "HeungKong Financial completed US$200 million Senior Notes issuance for China South City Holdings Limited",
            href: "https://mp.weixin.qq.com/s/t1T6G1Ru7FrQGEe1baGtTg"
          },
          {
            month: "AUG",
            day: "04",
            newsTitle:
              "HeungKong Financial completed US$220 million Senior Notes issuance for Redco Properties Group Limited",
            href: "https://mp.weixin.qq.com/s/bwfF5El_Oj4OjvnKODUp1w"
          },
          {
            month: "AUG",
            day: "03",
            newsTitle:
              "HeungKong Financial completed US$200 million Senior Notes issuance for Shinsun Real Estate Group Co., Ltd.",
            href: "https://mp.weixin.qq.com/s/jYyIv008vB_VDTDIGpylFA"
          },
          {
            month: "JUL",
            day: "16",
            newsTitle:
              " HeungKong Financial assisted Shandong Fengxiang (9977.HK) to successfully list on the Main Board of HKEX",
            href: "https://mp.weixin.qq.com/s/IdnBqzM0rJwmKkniqIrXlg"
          },
          {
            month: "JUN",
            day: "24",
            newsTitle:
              " HeungKong Financial completed US$500 million Senior Notes issuance for Agile Group Holdings Limited",
            href: "https://mp.weixin.qq.com/s/Ys27oMz39AbbLmCXszP0VA"
          },
          {
            month: "JUN",
            day: "17",
            newsTitle:
              " HeungKong Financial completed US$650 million Senior Notes issuance for New World Development Company Limited",
            href: "https://mp.weixin.qq.com/s/GjvOoP7vWbNfr3Suf-qJMw"
          },
          {
            month: "JUN",
            day: "12",
            newsTitle:
              "HeungKong Financial completed US$250 million Senior Notes issuance for Radiance Group",
            href: "https://mp.weixin.qq.com/s/27vC0npgR082YXgXlPWA5w"
          },
          {
            month: "MAY",
            day: "14",
            newsTitle:
              "HeungKong Financial completed US$600 million Senior Notes issuance for New World Development Company Limited",
            href: "https://mp.weixin.qq.com/s/CFi3c1daGbLdl9GludlyPw"
          },
          {
            month: "MAR",
            day: "09",
            newsTitle:
              "HeungKong Financial completed USD 450 million Guaranteed Senior Notes Issuance for Gemstones International Limited",
            href: "https://mp.weixin.qq.com/s/eng2z2CC0igeX7VOjXMofQ"
          },
          {
            month: "MAR",
            day: "06",
            newsTitle:
              "HeungKong Financial completed US$280 million Senior Notes issuance for Sinic Holdings (Group) Company Limited",
            href: "https://mp.weixin.qq.com/s/4bsee6zlVoPx-Z7vPM0vcA"
          },
          {
            month: "MAR",
            day: "02",
            newsTitle:
              "HeungKong Financial completed US$100 million Senior Notes issuance for Excellence Commercial Properties Co., Ltd. ",
            href: "https://mp.weixin.qq.com/s/hK_9B9d3R9I08BCbyLEalQ"
          },
          {
            month: "FEB",
            day: "28",
            newsTitle:
              "HeungKong Financial completed US$400 million Senior Notes issuancefor Guangzhou R&amp;F Properties Co., Ltd. ",
            href: "https://mp.weixin.qq.com/s/08FM99SsXQa5oizoRUIJWg"
          },
          {
            month: "FEB",
            day: "21",
            newsTitle:
              "HeungKong Financial completed US$200 million Senior Notes issuance for Modern Land (China) Co. Limited",
            href: "https://mp.weixin.qq.com/s/9ogPoIhl0-sWDefoDrXiiA"
          },
          {
            month: "FEB",
            day: "20",
            newsTitle:
              " HeungKong Financial completed US$225 million Senior Notes issuance for China South City Holdings Limited",
            href: "https://mp.weixin.qq.com/s/H7i-gU6jnLcrxxZ2qUsXyA"
          },
          {
            month: "FEB",
            day: "17",
            newsTitle:
              "HeungKong Financial completed USD 500 million Senior Notes Issuance for Hopson Development Holdings Limited",
            href: "https://mp.weixin.qq.com/s/g89BIuC76e9GG1cKgEX_RA"
          },
          {
            month: "JAN",
            day: "24",
            newsTitle:
              "HeungKong Financial completed USD 200 million Senior Notes Issuance for DaFa Properties Group Limited",
            href: "https://mp.weixin.qq.com/s/h_-WWlzHnnMDw74FugE_vA"
          },
          {
            month: "JAN",
            day: "21",
            newsTitle:
              " HeungKong Financial assists KAISA GROUP HOLDINGS LTD. to issue USD 500 million senior notes",
            href: "https://mp.weixin.qq.com/s/CaFSDHFZ4UeKPAAGUg-StA"
          },
          {
            month: "JAN",
            day: "14",
            newsTitle:
              "HeungKong Financial completed US$200 million Senior Notes issuance for China South City Holdings Limited",
            href: "https://mp.weixin.qq.com/s/LXjbsUqMnydtbgJSWnWeEQ"
          },
          {
            month: "JAN",
            day: "13",
            newsTitle:
              "HeungKong Financial completed US$300 million Senior Notes issuance for Radiance Group",
            href: "https://mp.weixin.qq.com/s/YZUbgRIc6Omjm8KGKx9PJg"
          },
          {
            month: "JAN",
            day: "09",
            newsTitle:
              "HeungKong Financial assists Golden Wheel Tiandi Holdings Company Limited to issue USD 200 million senior notes",
            href: "https://mp.weixin.qq.com/s/YYCgdiiFKFxvBSYBTko6Kg"
          }
        ],
        [
          {
            month: "DEC",
            day: "31",
            newsTitle:
              "HeungKong completed the placing of US$480 million new H shares for Guangzhou R&amp;F Properties Co., Ltd.",
            href: "https://mp.weixin.qq.com/s/F5L7YeTkttSYK4jbaRpjOg"
          },
          {
            month: "DEC",
            day: "19",
            newsTitle:
              "HeungKong Financial assisted in the successful listing of Times Neighborhood (9928.HK) on the Main Board of the SEHK",
            href: "https://mp.weixin.qq.com/s/vlxue-nPC4n5gWCIRgDoHw"
          },
          {
            month: "DEC",
            day: "06",
            newsTitle:
              "HeungKong Financial completed US$150 million Senior Notes issuance for China South City Holdings Limited",
            href: "https://mp.weixin.qq.com/s/eKB26IW1L5Qi-L5WWmPLGg"
          },
          {
            month: "DEC",
            day: "04",
            newsTitle:
              "HeungKong Financial completed US$250 million Senior Notes issuance for Redco Properties Group Limited",
            href: "https://mp.weixin.qq.com/s/zdaIiSm49HW8FshMW0tpxA"
          },
          {
            month: "NOV",
            day: "24",
            newsTitle:
              "HeungKong Financial assisted Huali University Group Limited(1756.HK) successfully listing on HKEX",
            href: "https://mp.weixin.qq.com/s/8SxG2WNBq2fPyEX5boVSbA"
          },
          {
            month: "NOV",
            day: "19",
            newsTitle:
              "HeungKong Financial completed US$200 million Senior Perpetual Capital Securities issuance for Agile Group Holdings Limited",
            href: "https://mp.weixin.qq.com/s/syjhKP3gm0oCaJo4PkeaiQ"
          },
          {
            month: "NOV",
            day: "15",
            newsTitle: "New Upgrade for 1690STOCK APP is Released Today! ",
            href: "https://mp.weixin.qq.com/s/x-KpuuOwvewVPJm-BQupmQ"
          },
          {
            month: "NOV",
            day: "14",
            newsTitle:
              "HeungKong Financial completed US$150 million Senior Notes issuance for Fantasia Holdings Group Co., Limited",
            href: "https://mp.weixin.qq.com/s/y3bkEGukWrJSP-a_Phh_oQ"
          },
          {
            month: "SEP",
            day: "27",
            newsTitle:
              "HeungKong Financial completed US$200 million Senior Notes issuance for Central China Real Estate Limited",
            href: "https://mp.weixin.qq.com/s/kk1g6P-SZJDMu0_PI8G0dw"
          },
          {
            month: "SEP",
            day: "19",
            newsTitle: "Grand launch of self-developed 1690STOCK trading APP",
            href: "https://mp.weixin.qq.com/s/szvfEDHFo98GaonWwBKCfA"
          },
          {
            month: "AUG",
            day: "20",
            newsTitle:
              "Annual Meeting of Futec International &amp; HeungKong Financial",
            href: "https://mp.weixin.qq.com/s/AgtnQBY1bTyZPBM612Ldew"
          },
          {
            month: "AUG",
            day: "02",
            newsTitle:
              "HeungKong Financial completed US$100 million Senior Notes issuance for Fantasia Holdings Group Co., Limited",
            href: "https://mp.weixin.qq.com/s/1niQjpfSxObCjqrSf34n6g"
          },
          {
            month: "AUG",
            day: "01",
            newsTitle:
              "HeungKong Financial completed US$250 million Senior Notes issuance for China Aoyuan Group Limited",
            href: "https://mp.weixin.qq.com/s/sq3RO6mttKcqhowlBIQhrQ"
          },
          {
            month: "JUL",
            day: "17",
            newsTitle:
              "HeungKong Financial completed US$300 million Senior Notes issuance for Ronshine China Holdings Ltd",
            href: "https://mp.weixin.qq.com/s/P2OX0W01NPF-rWgkrUDOZA"
          },
          {
            month: "JUL",
            day: "15",
            newsTitle:
              " HeungKong Financial completed US$950 million Senior Notes issuance for New World Development Company Limited",
            href: "https://mp.weixin.qq.com/s/kCk4aoIiFrQN5aqOzlHUvg"
          },
          {
            month: "JUL",
            day: "12",
            newsTitle:
              "HeungKong Financial completed US$400 million Senior Notes issuance for Logan Property Holdings Company Limited",
            href: "https://mp.weixin.qq.com/s/NvpaiYufQFSlGfUESUDbSQ"
          },
          {
            month: "JUL",
            day: "10",
            newsTitle:
              "HeungKong Financial completed US$400 million Senior Notes issuance for Times China Holdings Limited",
            href: "https://mp.weixin.qq.com/s/lYlPcpesFzdmI0zJSoObnw"
          },
          {
            month: "JUL",
            day: "08",
            newsTitle:
              "HeungKong Financial completed US$180 million Senior Notes issuance for DaFa Properties Group Limited",
            href: "https://mp.weixin.qq.com/s/__qAID2j1dXTdfIPEV1ufg"
          },
          {
            month: "JUL",
            day: "05",
            newsTitle:
              " HeungKong Financial completed US$450 million Senior Notes issuance for GuangZhou R&amp;F Properties Co., Ltd.",
            href: "https://mp.weixin.qq.com/s/X0squ-IiBZN532FDGuLZuQ"
          },
          {
            month: "JUN",
            day: "26",
            newsTitle:
              "HeungKong Financial completed US$250 million Senior Notes issuance for Redsun Properties Group Limited ",
            href: "https://mp.weixin.qq.com/s/9wDqHu-M6yEui9ixIdER1A"
          },
          {
            month: "JUN",
            day: "26",
            newsTitle:
              "HeungKong Financial completed US$500 million Senior Notes tap issuance for Kaisa Group Holdings Limited",
            href: "https://mp.weixin.qq.com/s/QPd89e8r65fAZZYfXzTKLg"
          },
          {
            month: "JUN",
            day: "24",
            newsTitle:
              " HeungKong Financial completed US$650 million Senior Notes issuance for NWS Holdings Limited",
            href: "https://mp.weixin.qq.com/s/Ns9EQwYRS2uJYJErb7vPiA"
          },
          {
            month: "JUN",
            day: "24",
            newsTitle:
              "HeungKong Financial completed US$500 million Senior Notes issuance for Hopson Development Holdings Limited",
            href: "https://mp.weixin.qq.com/s/t50iB9bduUnkZ4Iq2n5drg"
          },
          {
            month: "JUN",
            day: "12",
            newsTitle:
              " HeungKong Financial completed US$425 million Senior Notes issuance for Guangzhou R&amp;F Properties Co., Ltd. Proactively promoting financial development of top tier firm in the Greater Bay Area",
            href: "https://mp.weixin.qq.com/s/C_xerYN_rkqhf5arz-fvAA"
          },
          {
            month: "JUN",
            day: "10",
            newsTitle:
              " HeungKong Financial completed US$250 million Senior Notes issuance for C C Land Holdings Limited",
            href: "https://mp.weixin.qq.com/s/tGDyaO8gTtw6FHQf6f6lUQ"
          },
          {
            month: "JUN",
            day: "10",
            newsTitle:
              "HeungKong Financial completed US$600 million Senior Perpetual Capital Securities issuance for Agile Group Holdings Limited",
            href: "https://mp.weixin.qq.com/s/-Ofu8p8LTW8Oq1hID6CXhw"
          },
          {
            month: "MAY",
            day: "10",
            newsTitle:
              "HeungKong Financial completed US$500 million Senior Notes issuance for Yankuang Group (Cayman) Limited",
            href: "https://mp.weixin.qq.com/s/sEsaTInagS-iX8UfdtgrFQ"
          },
          {
            month: "MAY",
            day: "09",
            newsTitle:
              "Zhang Xiaoli, Vice Chairman of Shenzhen Chinese People's Political Consultative Conference (CPPCC) visited HeungKong Financial Group and conducted research activity for private enterprises economic development",
            href: "https://mp.weixin.qq.com/s/IaU3GeaVHNicsdtwLLKxCw"
          },
          {
            month: "APR",
            day: "10",
            newsTitle:
              "HeungKong Financial completed US$300 million Senior Notes issuance for Redsun Properties Group Limited",
            href: "https://mp.weixin.qq.com/s/4MGFgLP6g3JJiAvf59_rJg"
          },
          {
            month: "APR",
            day: "03",
            newsTitle:
              " HeungKong Financial completed US$100 million Senior Notes issuance for Excellence Commercial Properties Co., Ltd. ",
            href: "https://mp.weixin.qq.com/s/JBmwJB5IdPRBLdCqe3TsSA"
          },
          {
            month: "MAR",
            day: "22",
            newsTitle:
              "HeungKong Financial completed US$200 million Senior Notes issuance for China South City Holdings Limited",
            href: "https://mp.weixin.qq.com/s/p5gB8nN5xltTU73F7DDiDQ"
          },
          {
            month: "MAR",
            day: "06",
            newsTitle:
              "HeungKong Financial assisted Singapore well-known food and beverage group K2 F&amp;B Holdings Limited (2108.HK) in high-end pricing and successfully listing on HKEx",
            href: "https://mp.weixin.qq.com/s/LjG4tyhyx2qkP2i8R4f1uw"
          },
          {
            month: "MAR",
            day: "01",
            newsTitle:
              "HeungKong Financial completed US$500 million Senior Notes issuance for Agile Group Holdings Ltd.",
            href: "https://mp.weixin.qq.com/s/1V7VvgVpua4KGp0KZAw-kw"
          },
          {
            month: "FEB",
            day: "22",
            newsTitle:
              " HeungKong Financial completed US$350 million Senior Notes issuance for KWG Group Holdings Ltd.",
            href: "https://mp.weixin.qq.com/s/QpB8aWikaxXwuFu44u7NfA"
          },
          {
            month: "FEB",
            day: "21",
            newsTitle:
              " HeungKong Financial completed US$825 million Dual Tranche Senior Notes issuance for GuangZhou R&amp;F Properties Co., Ltd.",
            href: "https://mp.weixin.qq.com/s/n-Mx8lq9ZUGdv6Qa5AJTcQ"
          },
          {
            month: "JAN",
            day: "18",
            newsTitle:
              "HeungKong Financial completed US$300 million Senior Notes issuance for GuangZhou R&amp;F Properties Co., Ltd.",
            href: "https://mp.weixin.qq.com/s/g2EzDfUTRPUqWQ2KZ3f6Bw"
          }
        ],
        [
          {
            month: "DEC",
            day: "21",
            newsTitle:
              "HeungKong Financial was the joint underwriter of 360 Finance (QFIN.US) at NASDAQ",
            href: "https://mp.weixin.qq.com/s/mTUdozQs2uvmlBSOOEGN5w"
          },
          {
            month: "DEC",
            day: "04",
            newsTitle:
              " HeungKong Financial assisted New World China Land in its first green bond issue",
            href: "https://mp.weixin.qq.com/s/f5i2MuyJGpWkShOubFIF4g"
          },
          {
            month: "DEC",
            day: "03",
            newsTitle:
              " HeungKong Financial was the sole financial adviser of Uni-Bio Science Group Limited (690.HK) in an asset disposal transaction",
            href: "https://mp.weixin.qq.com/s/NPwjiE1Bk62vq_xbdmu-Pg"
          },
          {
            month: "SEP",
            day: "10",
            newsTitle:
              "Grand Opening of Futec International &amp; HeungKong Financial Tsim Sha Tsui head office",
            href: "https://mp.weixin.qq.com/s/Rs4hPi9ILIDmc6cj0AxlOw"
          },
          {
            month: "AUG",
            day: "08",
            newsTitle:
              "Futec International becomes strategic investor of Golden River Group – a leading PRC cross-border e-commerce service provider",
            href:
              "https://mp.weixin.qq.com/s?__biz=MzIwNjg5NTY2OA==&amp;mid=2247483983&amp;idx=1&amp;sn=d3ae9770c2511004e24658022ee3c35c&amp;chksm=971bed14a06c6402cb21c19966700428b051d1a6d64d0f407f5306347395cbf54cf204e22fcb&amp;mpshare=1&amp;scene=1&amp;srcid=0827rAu3dfiSnyYQlu3NELUS&amp;pass_ticket=B3ydooqjpVFwS7ejijUGHKp2M8VFkvp0j9p7cip%2BzP3mAiTFnhJ1d8NxYW3ItAEo#rd"
          },
          {
            month: "AUG",
            day: "03",
            newsTitle:
              " Company Lunch of Futec International &amp; HeungKong Financial",
            href:
              "https://mp.weixin.qq.com/s?__biz=MzIwNjg5NTY2OA==&amp;mid=2247483972&amp;idx=1&amp;sn=74ccce75222339c66050ca3c10f18170&amp;chksm=971bed1fa06c6409ec7c6c2d2ffcd7d0c4213ecbe0a8a2e78e27a97c8ff338b222c2d6b1a1ed&amp;mpshare=1&amp;scene=1&amp;srcid=08274l1WmT1uDNPm3LttSW1n&amp;pass_ticket=B3ydooqjpVFwS7ejijUGHKp2M8VFkvp0j9p7cip%2BzP3mAiTFnhJ1d8NxYW3ItAEo#rd"
          },
          {
            month: "JUL",
            day: "18",
            newsTitle:
              " Futec and DotBio announce strategic partnership - focus on next generation of immuno-oncology drugs based on domain antibodies",
            href:
              "https://mp.weixin.qq.com/s?__biz=MzIwNjg5NTY2OA==&amp;mid=2247483966&amp;idx=1&amp;sn=34c33eef7d90749a74f3e2a092fa4593&amp;chksm=971bed65a06c6473a42aee8adde838ebad8c820e1478c5c32af9b7592d6537872e59a0a2d77b&amp;mpshare=1&amp;scene=1&amp;srcid=0827RLDh6oZ7FXrClVelAoqQ&amp;pass_ticket=B3ydooqjpVFwS7ejijUGHKp2M8VFkvp0j9p7cip%2BzP3mAiTFnhJ1d8NxYW3ItAEo#rd"
          },
          {
            month: "FEB",
            day: "14",
            newsTitle:
              "Futec International &amp; HeungKong welcome Chairman of Shenzhen Committee with his team to Hong Kong",
            href:
              "https://mp.weixin.qq.com/s?__biz=MzIwNjg5NTY2OA==&amp;mid=2247483734&amp;idx=1&amp;sn=44b68031df8b2ade3e32430de8f6dfc1&amp;chksm=971bee0da06c671b1ef7283a7f1a18b248fa71bb9da2c5da8b9b926849f831e4dbb7f5cd186e&amp;mpshare=1&amp;scene=1&amp;srcid=08274MtszNaUzfciKhjrLSqW&amp;pass_ticket=B3ydooqjpVFwS7ejijUGHKp2M8VFkvp0j9p7cip%2BzP3mAiTFnhJ1d8NxYW3ItAEo#rd"
          },
          {
            month: "JAN",
            day: "27",
            newsTitle: " Inheritance &amp; develop",
            href: "https://eastweek.my-magazine.me/main/74811"
          },
          {
            month: "JAN",
            day: "17",
            newsTitle: "Winning through right money mindset",
            href: "http://eastweek.my-magazine.me/main/74602"
          }
        ],
        [
          {
            month: "DEC",
            day: "20",
            newsTitle:
              " Futec International Holdings Limited leads series B+ investment in DDC Enterprise Limited",
            href:
              "https://mp.weixin.qq.com/s?__biz=MzIwNjg5NTY2OA==&amp;mid=2247483705&amp;idx=1&amp;sn=c30420d34a500e211ed4d4fc322f47f3&amp;chksm=971bee62a06c677470a2ec7250bb657f4f879883cd45de08bf6dc6e44c843285ca28798f7619&amp;mpshare=1&amp;scene=1&amp;srcid=0827oniwpYn6ECLWZGbJRy73&amp;pass_ticket=B3ydooqjpVFwS7ejijUGHKp2M8VFkvp0j9p7cip%2BzP3mAiTFnhJ1d8NxYW3ItAEo#rd"
          }
        ]
      ],
      cueerntYear: 0
    };
  },
  mounted() {
    this.news = media.news;
  },
  methods: {
    onChangeYear(item, index) {
      this.year = item;
      this.cueerntYear = index;
    }
  }
};
</script>

<style lang="scss" scoped>
.media {
  font-size: 18px;
  .image {
    width: 100%;
    height: 638px;
    img {
      width: 100%;
      height: 100%;
    }
  }
  .content {
    // width: 1200px;
    margin: auto;
    margin-bottom: 100px;
    .title {
      font-size: 32px;
      font-family: MHeiHK;
      font-weight: 500;
      color: #00b2e3;
      margin-bottom: 30px;
    }
    .box {
      display: flex;
      .left {
        width: 535px;
        height: 410px;
        background: url("../../assets/image/img_archive.png") no-repeat
          no-repeat;
        background-size: 100% 100%;
        margin-left: 93px;
        .title {
          font-size: 24px;
          font-weight: bold;
          color: #ffffff;
          margin-top: 90px;
        }
        .item-box {
          height: 170px;
          overflow: hidden;
          overflow-y: auto;
          .item {
            height: 40px;
            font-size: 24px;
            font-weight: bold;
            color: #0073b6;
          }
          .titleC {
            color: #fb821c;
          }
        }
        .item-box::-webkit-scrollbar {
          display: none;
        }
      }
      .right {
        .year {
          text-align: left;
          width: 1023px;
          height: 58px;
          font-size: 36px;
          font-weight: 400;
          color: #8e8e8d;
          border-bottom: 1px solid #8e8e8d;
          margin-top: 60px;
        }
        .list {
          display: flex;
          margin-top: 40px;
          .right-img {
            width: 150px;
            height: 132px;
            img {
              width: 100%;
              height: 100%;
              object-fit: contain;
            }
          }
          .data {
            // width: 150px;
            // height: 132px;
            width: 100px;
            height: 80px;
            background: url("../../assets/image/dataBg.png") no-repeat no-repeat;
            background-size: 100% 100%;
            margin: auto 0;
            .month {
              color: #ffffff;
              // font-size: 28px;
              font-size: 20px;
              // margin-top: 10px;
              margin-top: 4px;
            }
            .day {
              color: #004877;
              // font-size: 50px;
              font-size: 28px;
            }
          }
          .text {
            width: 841px;
            font-size: 20px;
            font-weight: 400;
            color: #4d4d4d;
            text-align: left;
            margin: auto;
            margin-left: 30px;

            a {
              color: #1e87f0;
              text-decoration: none;
              cursor: pointer;
            }
            a:hover {
              text-decoration: underline;
            }
          }
        }
      }
    }
  }
}
</style>

<template>
  <div class="career_box">
    <div class="careet_top">
      <div class="title">Work with FUTEC</div>
      <div class="text">
        FUTEC Financial is one of the leading investment banks in the Greater
        Bay Area.
      </div>
      <div class="bgc">
        <div class="bgc_div">We empower our people to learn and thrive.</div>
      </div>
      <!-- <div class="img"><img src="@/assets/image/Career/img_caeer_1.png" /></div> -->
      <div class="img"><img src="@/assets/image/Career/img_caeer_3.png" /></div>
    </div>
    <div class="career_centre">
      <div class="title">Join Us</div>
      <div class="text">
        At FUTEC Financial, you will get access to a diverse pool of
        opportunities, resources and networks that fuels your career
        progression.
      </div>
      <div class="text">
        We embrace changes through relentless focus on innovation and value
        creation for better future.
      </div>
    </div>
    <div class="career_centre_two">
      <!-- swiper-no-swiping -->
      <div class="swiper swiper-container swiper-no-swiping">
        <div class="swiper-wrapper">
          <div class="swiper-slide">
            Asset Management
          </div>
          <div class="swiper-slide">
            Fixed Income
          </div>
          <div class="swiper-slide">
            Investment Bank
          </div>
          <div class="swiper-slide">
            Research
          </div>
          <div class="swiper-slide">
            1690 Stock Fintech
          </div>
        </div>
        <div class="swiper-button-next"></div>
        <div class="swiper-button-prev"></div>
        <!-- <div class="swiper-pagination"></div> -->
      </div>
      <div class="icon_arrows">
        <img src="@/assets/image/Career/icon_arrows_right.png" />
      </div>
      <div class="centre" v-if="currentIndexs === 0">
        <p>Structured Financing and Strategic Equity Investment</p>
        <p>Discretionary Investment Management Services</p>
      </div>
      <div class="centre" v-if="currentIndexs === 1">
        <p>DCM : Bond Issuance, Underwriting and distribution</p>
        <p>FUTEC Fond Fund managed by our professional team</p>
      </div>
      <div class="centre" v-if="currentIndexs === 2">
        <p>
          Corporate finance: IPO sponsor, financial adviser, independent
          financial adviser, compliance adviser, secondary market fundraising,
          private equity and principal investments, mergers and acquisitions and
          restructuring.
        </p>
      </div>
      <div class="centre" v-if="currentIndexs === 3">
        <p>
          Cutting-edge knowledge and analytical skill to bring clients
          industry-leading analysis and investment advice with core focus on
          Greater China.
        </p>
      </div>
      <div class="centre" v-if="currentIndexs === 4">
        <p>
          AI fintech trading platform aims to redefine the definition of
          INVESTMENT
        </p>
      </div>
      <div class="right">
        <img src="@/assets/image/Career/img_caeer_2.png" />
      </div>
    </div>
    <div class="career_centre career_centre_top ">
      <div class="title">Meet Us</div>
      <div class="text">
        With our unique culture of collaboration, we offer a supportive
        workplace environment that fuels persistent growth of our people.
      </div>
      <div class="text">
        We are looking for people who aspire to bring changes and contribute to
        the sustainable growth of FUTEC Financial.
      </div>
    </div>
    <div class="career_bottom">
      <div class="list" @click="goDetails('personalIntroduction', 2)">
        <div class="img_head">
          <img src="@/assets/image/Career/img_head_sean.png" />
        </div>
        <div class="list_name">Sean Kan</div>
        <div class="list_position">
          <span>Summer intern</span><span> HKUST</span>
        </div>
        <div class="list_img"><img src="@/assets/image/Career/icon.png" /></div>
        <div class="list_text">
          Joining FUTEC Financial offers valuable experience for students to
          have a glimpse on the investment banking industry.
        </div>
      </div>
      <div class="list" @click="goDetails('personalIntroduction', 3)">
        <div class="img_head">
          <img src="@/assets/image/Career/img_head_carman.png" />
        </div>
        <div class="list_name">Carman Ma</div>
        <div class="list_position">
          <span>Graduate Analyst</span><span> CUHK</span>
        </div>
        <div class="list_text list_text_top">
          FUTEC is filled with a round of highly experienced and professional
          bankers who are all incredibly knowledgeable, supportive and energetic
          which generally a joy to be around.
        </div>
        <div class="list_img_right">
          <img src="@/assets/image/Career/icon_2.png" />
        </div>
      </div>
      <div class="list" @click="goDetails('personalIntroduction', 1)">
        <div class="img_head">
          <img src="@/assets/image/Career/img_head_chloe.png" />
        </div>
        <div class="list_name">Chloe Wong</div>
        <div class="list_position">
          <span>Summer intern</span><span> HKU</span>
        </div>
        <div class="list_img"><img src="@/assets/image/Career/icon.png" /></div>
        <div class="list_text">
          The learning and discussion process was very inspiring in such a way
          that it helps shape my career development goals.
        </div>
      </div>
    </div>
    <bottom></bottom>
  </div>
</template>

<script>
import Swiper from "swiper";
import bottom from "@/components/bottom.vue";
// var realIndex = 0;
export default {
  name: "Career",
  components: {
    bottom
  },
  data() {
    return {
      currentTitle: "",
      activeName: "",
      active: 0,
      address: "Hong Kong",
      showAddress: false,
      showJob: false,
      job: "Select a job field",
      avtiveIndex: 0,
      currentIndexs: -1
    };
  },
  mounted() {
    this.currentIndexs = 2;
    const vm = this;
    new Swiper(".swiper-container", {
      direction: "vertical",
      loop: true,
      centeredSlides: true,
      loopAdditionalSlides: 100,
      loopFillGroupWithBlank: true,
      // 如果需要分页器
      pagination: ".swiper-pagination",
      paginationClickable: true,
      slidesPerView: 5,
      spaceBetween: 20,
      slidesPerGroup: 1,
      initialSlide: 2, //默认显示第2个
      observer: true, // 修改swiper自己或子元素时，自动初始化swiper
      observeParents: true, // 修改swiper的父元素时，自动初始化swiper
      nextButton: ".swiper-button-next",
      prevButton: ".swiper-button-prev",
      onClick: function(swiper) {
        const realIndex = swiper.realIndex;
        vm.onChageIndex(realIndex);
      },
      onTransitionEnd: function(swiper) {
        const realIndex = swiper.realIndex;
        vm.onChageIndex(realIndex);
      }
    });
  },
  methods: {
    onChageIndex(realIndex) {
      this.currentIndexs = realIndex;
    },
    openItem(item, index) {
      if (this.currentIndex === index) {
        this.currentIndex = -1;
      } else {
        this.currentIndex = index;
        this.currentTitle = item;
      }
    },
    handleChange(val) {
      // console.log(val);
    },
    selectAddress() {
      this.showJob = false;
      this.showAddress = !this.showAddress;
    },
    select(index) {
      if (index === 0) {
        this.address = "Hong Kong";
      } else if (index === 1) {
        this.address = "Shen Zhen";
      }
      this.showAddress = false;
    },
    onClickJob() {
      this.showAddress = false;
      this.showJob = !this.showJob;
    },
    selectJob(item, index) {
      this.job = item;
      this.showJob = false;
    },
    goDetails(path, id) {
      this.$router.push({ name: path, params: { id: id } });
    }
  }
};
</script>

<style lang="scss" scoped>
.career_box {
  .careet_top {
    margin-top: 76px;
    height: 500px;
    position: relative;
    .title {
      height: 26px;
      font-size: 34px;
      // font-weight: bold;
      color: #00b2e3;
      line-height: 24px;
      margin-top: 74px;
      margin-left: 407px;
      text-align: left;
    }
    .text {
      width: 480px;
      height: 53px;
      font-size: 22px;
      font-weight: 300;
      color: #333333;
      line-height: 32px;
      margin-top: 38px;
      margin-left: 407px;
      text-align: left;
    }
    .bgc {
      width: 1920px;
      height: 140px;
      background: #004877;
      margin-top: 60px;
      display: flex;
      align-items: center;
      .bgc_div {
        width: 464px;
        height: 60px;
        background: #1f5e88;
        border-radius: 30px 30px 0px 30px;
        margin-left: 405px;
        font-size: 22px;
        color: #ffffff;
        font-weight: 300;
        line-height: 60px;
      }
    }
    .img {
      position: absolute;
      left: 47%;
      top: -5%;
      // width: 706px;
      // height: 500px;
      width: 670px;
      height: 448px;
      img {
        width: 100%;
        height: 100%;
        object-fit: cover;
      }
    }
  }
  .career_centre {
    .title {
      font-size: 34px;
      // font-weight: bold;
      color: #00b2e3;
      margin-bottom: 35px;
    }
    .text {
      width: 816px;
      margin: auto;
      font-size: 22px;
      color: #333333;
      text-align: center;
      line-height: 36px;
    }
  }
  .career_centre_top {
    margin-top: 100px;
  }
  .career_centre_two {
    display: flex;
    align-items: center;
    height: 400px;
    background: #f0f4f7;
    border-radius: 8px;
    overflow: hidden;
    margin-top: 90px;
    .swiper {
      margin-right: 17px;
      margin-left: 360px;
      // width: 360px;
      width: 420px;
      height: 400px;
      // margin-right: 30px;
    }

    .swiper-slide {
      width: 320px;
      margin-left: 40px;
      text-align: center;
      font-size: 28px;
      background: #fff;
      display: flex;
      justify-content: center;
      align-items: center;
      box-shadow: 0px 0px 13px 0px rgba(0, 0, 0, 0.09);
      border-radius: 10px;
    }

    // .swiperBgc {
    //   background: #20adce;
    //   color: #ffffff;
    // }

    .swiper-slide-active {
      background: #20adce;
      color: #fff;
    }

    .swiper-button-prev {
      width: 64px;
      height: 64px;
      background: url("../../assets/image/Career/icon_up_put.png") no-repeat
        no-repeat;
      background-size: 100% 100%;
      left: 85%;
      top: 28%;
    }
    .swiper-button-next {
      width: 64px;
      height: 64px;
      background: url("../../assets/image/Career/icon_down_put.png") no-repeat
        no-repeat;
      background-size: 100% 100%;
      right: 0;
      top: 70%;
    }

    .swiper-pagination {
      left: 0;
    }
    .swiper-pagination-bullet {
      width: 30px;
      height: 30px;
    }

    .icon_arrows {
      display: flex;
      width: 7px;
      height: 16px;
      margin-left: -30px;
      img {
        width: 100%;
        height: 100%;
        object-fit: cover;
      }
    }

    .centre {
      width: 316px;
      font-size: 20px;
      color: #333333;
      line-height: 36px;
      margin-right: 45px;
      text-align: left;
      margin-left: 40px;
    }

    .right {
      width: 409px;
      height: 293px;
      img {
        width: 100%;
        height: 100%;
        object-fit: cover;
      }
    }
  }

  .career_bottom {
    display: flex;
    justify-content: space-around;
    margin: 100px 380px;
    .list {
      width: 350px;
      height: 410px;
      background: url("../../assets/image/Career/bg_meet_us.png") no-repeat;
      background-size: 100% 100%;
      // box-shadow: 0px 0px 10px #386fcf inset;

      // border: 1px solid rgba(0, 168, 236, 1);
      .img_head {
        width: 116px;
        height: 116px;
        display: flex;
        margin: -50px auto 0;
        img {
          width: 100%;
          height: 100%;
          object-fit: cover;
        }
      }
      .list_name {
        font-size: 30px;
        font-weight: 500;
        color: #ffffff;
        line-height: 24px;
        margin: 20px auto;
      }
      .list_position {
        display: flex;
        justify-content: center;
        span {
          font-size: 20px;
          color: #ffffff;
          height: 30px;
          border: 1px solid #20aece;
          border-radius: 4px;
          padding: 0 5px;
          margin-right: 5px;
        }
      }
      .list_img {
        width: 36px;
        height: 29px;
        display: flex;
        margin: 40px 0 20px 28px;
        img {
          width: 100%;
          height: 100%;
        }
      }

      .list_img_right {
        width: 36px;
        height: 29px;
        display: flex;
        float: right;
        margin-right: 25px;
        img {
          width: 100%;
          height: 100%;
        }
      }

      .list_text {
        width: 295px;
        font-size: 18px;
        font-weight: 300;
        color: #f0f4f7;
        line-height: 30px;
        margin: auto;
        text-align: left;
      }

      .list_text_top {
        margin-top: 30px;
      }
    }
    .list:hover {
      box-shadow: 0px 0px 30px #999;
      border-radius: 10px;
    }
  }
}
</style>

<template>
  <div class="securities-brokerage-wrap">
    <div class="securities-brokerage-wrap-content">
      <div class="image">
        <img
          src="../../../assets/image/OurOffering/banner_our_offering_04.png"
        />
      </div>
      <div class="content-top">
        <div
          class="list"
          @click="onChange(index)"
          v-for="(item, index) in $t('OurOffering.securitiesBrokerageData')"
          :key="index"
        >
          <div class="image">
            <img :src="item.icon" />
          </div>
          <div class="title" :class="{ titleActive: activeIndex === index }">
            {{ item.title }}
          </div>
        </div>
      </div>
      <div class="content-text">
        <div
          v-for="(item, index) in $t('OurOffering.securitiesBrokerageData')"
          :key="index"
        >
          <div v-if="activeIndex == index">
            <div v-for="(item1, index1) in item.text" :key="index1">
              {{ item1.title }}
              <div v-for="(item2, index2) in item1.text" :key="index2">
                <div>{{ item2 }}</div>
              </div>
            </div>
          </div>
        </div>
      </div>
      <!-- <div class="content" v-if="activeIndex === 0">
        <div class="swiper-container">
          <div class="swiper-wrapper">
            <div class="swiper-slide">
              <img src="../../../assets/image/bond/bond01.png" />
              <img src="../../../assets/image/bond/bond02.png" />
              <img src="../../../assets/image/bond/bond03.png" />
              <img src="../../../assets/image/bond/bond04.png" />
              <img src="../../../assets/image/bond/bond05.png" />
            </div>
            <div class="swiper-slide">
              <img src="../../../assets/image/bond/bond06.png" />
              <img src="../../../assets/image/bond/bond07.png" />
              <img src="../../../assets/image/bond/bond08.png" />
              <img src="../../../assets/image/bond/bond09.png" />
              <img src="../../../assets/image/bond/bond10.png" />
            </div>
            <div class="swiper-slide">
              <img src="../../../assets/image/bond/bond11.png" />
              <img src="../../../assets/image/bond/bond12.png" />
              <img src="../../../assets/image/bond/bond13.png" />
              <img src="../../../assets/image/bond/bond14.png" />
              <img src="../../../assets/image/bond/bond15.png" />
            </div>
            <div class="swiper-slide">
              <img src="../../../assets/image/bond/bond16.png" />
              <img src="../../../assets/image/bond/bond17.png" />
              <img src="../../../assets/image/bond/bond18.png" />
              <img src="../../../assets/image/bond/bond19.png" />
            </div>
          </div>
          如果需要分页器
          <div class="swiper-pagination swiper-pagination-top"></div>

          如果需要导航按钮
          <div class="swiper-button-prev"></div>
          <div class="swiper-button-next"></div>
        </div>
      </div>
      <div class="fixed_income" v-if="activeIndex === 0">
        <img src="../../../assets/image/OurOffering/img_fixed_income.png" />
      </div> -->
      <div v-if="activeIndex === 0">
        <div class="bottom-item">
          <span :class="{ spanBg: activeYears == 0 }" @click="onChangeYears(0)"
            >2021</span
          >
          <span :class="{ spanBg: activeYears == 1 }" @click="onChangeYears(1)"
            >2020</span
          >
          <span :class="{ spanBg: activeYears == 2 }" @click="onChangeYears(2)"
            >2019</span
          >
        </div>
        <div v-if="activeYears === 0">
          <img
            src="@/assets/image/OurOffering/img_out_offering_fixed_in2021.png"
          />
        </div>
        <div v-if="activeYears === 1">
          <img
            src="@/assets/image/OurOffering/img_out_offering_fixed_in2020.png"
          />
        </div>
        <div v-if="activeYears === 2">
          <img
            src="@/assets/image/OurOffering/img_out_offering_fixed_in2019.png"
          />
        </div>
      </div>
      <div class="fixed_income" v-if="activeIndex === 0">
        <img src="../../../assets/image/OurOffering/img_fixed_income.png" />
      </div>
      <div v-if="activeIndex === 2">
        <div>
          <img
            src="@/assets/image/OurOffering/img_out_offerin_fixed_SFinancing.png"
          />
        </div>
      </div>
    </div>
    <bottom></bottom>
  </div>
</template>

<script>
import Swiper from "swiper";
import bottom from "@/components/bottom.vue";
export default {
  name: "securitiesBrokerage",
  components: {
    bottom
  },
  data() {
    return {
      activeIndex: 0,
      activeYears: 0
    };
  },
  mounted() {
    document.body.scrollTop = document.documentElement.scrollTop = 0;
    new Swiper(".swiper-container", {
      loop: true,
      // 如果需要分页器
      pagination: ".swiper-pagination",
      paginationClickable: true,
      // 如果需要前进后退按钮
      nextButton: ".swiper-button-next",
      prevButton: ".swiper-button-prev"
    });
  },
  methods: {
    onChange(index) {
      this.activeIndex = index;
    },
    onChangeYears(index) {
      this.activeYears = index;
    }
  }
};
</script>

<style lang="scss" scoped>
.securities-brokerage-wrap {
  .securities-brokerage-wrap-content {
    width: 62.5vw;
    margin: auto;
    margin: 0 auto 100px;
    .image {
      height: 480px;
      margin: 60px auto 0;
      img {
        width: 100%;
        height: 100%;
        object-fit: contain;
      }
    }
    .content-top {
      display: flex;
      justify-content: space-around;
      margin-top: 50px;
      .list {
        .image {
          width: 175px;
          height: 175px;
          margin: auto;
          img {
            width: 100%;
            height: 100%;
            object-fit: cover;
          }
        }
        .title {
          width: 250px;
          font-size: 32px;
          // font-weight: bold;
          color: #00b2e3;
          margin-top: 30px;
        }
        .titleActive {
          font-weight: bold;
        }
        // .title:hover {
        //   font-weight: bold;
        // }
      }
    }
    .content-text {
      margin: auto;
      width: 971px;
      font-size: 16px;
      font-weight: 400;
      color: #333333;
      line-height: 24px;
      text-align: left;
      margin-top: 90px;
      div {
        margin-bottom: 30px;
      }
    }
    .data-list {
      display: flex;
      justify-content: space-around;
      margin-top: 50px;
      .data-list-top {
        .data-list-img {
          width: 175px;
          height: 175px;
          margin: 60px auto;
          img {
            width: 100%;
            height: 100%;
            object-fit: contain;
          }
        }
        .data-list-title {
          font-size: 32px;
          font-weight: 500;
          color: #00b2e3;
          margin-bottom: 20px;
        }
      }
      .data-list-text {
        width: 966px;
        font-size: 16px;
        color: #333333;
        text-align: left;
        margin: auto;
        margin-bottom: 30px;
      }
      .data-list-image {
        width: 1003px;
        height: 322px;
        margin: auto;
        img {
          width: 100%;
          height: 100%;
          object-fit: contain;
        }
      }
    }
    .swiper-container {
      height: 400px;
      width: 100%;
      .swiper-wrapper {
        .swiper-slide {
          width: 100%;
          height: 100%;
          // background-color: #42b983;
          text-align: center;
          line-height: 500px;
          img {
            width: 220px;
            height: 260px;
            object-fit: contain;
          }
        }
      }
      .swiper-button-prev {
        background: url("../../../assets/image/OurOffering/icon_left_1.png")
          no-repeat no-repeat;
        background-size: 100% 100%;
      }
      .swiper-button-next {
        background: url("../../../assets/image/OurOffering/icon_right_1.png")
          no-repeat no-repeat;
        background-size: 100% 100%;
      }
    }
    .fixed_income {
      width: 917px;
      height: 429px;
      margin: 50px auto 0;
      img {
        width: 100%;
        height: 100%;
        object-fit: contain;
      }
    }
    .bottom-item {
      display: flex;
      justify-content: space-evenly;
      margin: 60px auto;
      span {
        width: 151px;
        height: 48px;
        font-size: 32px;
        // font-weight: bold;
        color: #ffffff;
        background: url("../../../assets/image/OurOffering/butten01.png")
          no-repeat no-repeat;
        background-size: 100% 100%;
      }
      .spanBg {
        background: url("../../../assets/image/OurOffering/butten05.png")
          no-repeat no-repeat;
        background-size: 100% 100%;
      }
    }
  }
}
</style>
